import React, { Component, Suspense, lazy } from 'react'
import { Route, Link, Redirect, Switch } from 'react-router-dom'

import Hotkeys from 'react-hot-keys';

import AuthLayout from '../../layouts/AuthLayout'
import menuData  from '../../routes'
import Exception from '../../components/Exception'
import BasicLayout from '../../layouts/BasicLayout'
import Login from '../../elements/login/views'
import Register from '../../elements/register/views'
import DeleteAccount from '../../elements/login/views/deleteAccount'
import ForgotPassword from '../../containers/forgotPassword'
import ForgotPasswordDirect from '../../elements/login/views/forgotPassword'
import '../../index.css'
import _ from 'lodash'
import { pageTitle } from '../../settings'
import { loadReCaptcha } from 'react-recaptcha-google'
import GlobalSearchBarModal from '../../components/Search/searchModal'
import {connect} from 'react-redux'
import ClearCache from 'react-clear-cache';

const Exp = () => (<Exception
  type="404"
  desc={'You Seems lost !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)
const Exp403 = () => (<Exception
  type="403"
  desc={'Sorry You Don\'t have access to this area !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)
const CacheApp= () => {


  return (
    <div>
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <div>
            {!isLatestVersion && (
              <p className={'updateBtn'}>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    emptyCacheStorage();
                  }}
                >
                  Update version
                </a>
              </p>
            )}
          </div>
        )}
      </ClearCache>
    </div>
  );
};

class BasicLayoutWrapper extends Component {


  render() {

    const { menuData, component, path } = this.props

    let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null

    if (!user) {
      return (<Redirect to="/login" />)
    }

    let menuItem = _(menuData)
      .thru(function (coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({ 'path': path })

    if (menuItem.authority !== undefined && menuItem.authority.indexOf(user.userType) === -1) {
      console.log('this user should not be here ', path)
      return <Exp403 />
    }


    if (!menuItem.title) {
      menuItem.title = !!menuItem.name ? menuItem.name : 'Untitled'
    }



    return (
      <BasicLayout
        location={window.location}
        title={pageTitle}
        pageTitle={`${menuItem.title} - Scizers IOT Pvt. Ltd.`}
        menuData={menuData}>
        <CacheApp/>
        {!!component ?
          <Suspense fallback={<div>Loading...</div>}>

            <this.props.component />
          </Suspense> : <Exp />}

      </BasicLayout>)
  }
}

class App extends Component {


  constructor(props) {
    super(props)
    this.state = {
      token: localStorage.getItem('token'),
      user: (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
    }

  }
  componentDidMount() {
    loadReCaptcha();
  }

  onKeyDown(keyName, e, handle) {
    console.log("test:onKeyDown", keyName, e, handle)
  }


  render() {

    const { user } = this.state
    return (<Suspense fallback={<div>Loading...</div>}>
      <Hotkeys
        keyName="ctrl+k,ctrl+space"
        onKeyDown={this.onKeyDown.bind(this)}
      >

      <GlobalSearchBarModal />
      <Switch>

        {menuData.map((item, key) => {
          if (!item.children) {
            return (<Route exact path={item.path} key={item.path} render={(route) => {
              return <BasicLayoutWrapper component={item.component} path={item.path} user={user} menuData={menuData} />
            }} />)
          }
        })}

        {menuData.map((item, key) => {
          if (item.children) {
            return item.children.map((child, k) => {
              return (<Route exact path={child.path} key={child.path} render={(route) => {
                return <BasicLayoutWrapper component={child.component} path={child.path} user={user}
                  menuData={menuData} />
              }} />)
            })
          }
        })}

        <Route exact path="/login" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <Login />
            </AuthLayout>
          )
        }} />

        <Route exact path="/register" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <Register/>
            </AuthLayout>
          )
        }}/>
        <Route exact path="/deleteAccount" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <DeleteAccount/>
            </AuthLayout>
          )
        }}/>

        <Route exact path="/register" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <Register/>
            </AuthLayout>
          )
        }}/>

        <Route exact path="/forgotPassword" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <ForgotPassword />
            </AuthLayout>
          )
        }} />
        <Route exact path="/forgotPasswordDirect" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <ForgotPasswordDirect />
            </AuthLayout>
          )
        }} />

        <Route exact path="/" render={(route) => {
          return (
            <Redirect to="/dashboard" />
          )
        }} />


      </Switch>

      </Hotkeys>
    </Suspense>)
  }
}

const mapStateToProps = ({ global, router }) => ({
  showGlobalSearchBar: global.showGlobalSearchBar,
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)


// export default App
