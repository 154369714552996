export default (
    state = {
        conflictLoader: false,
        conflictList: {data:[]},
        conflictResp: {},
        farmerData: null
    },
    action
) => {
    switch (action.type) {
        case "CONFLICTS_SHOW_LOADER":
            return { ...state, conflictLoader: true };
        case "CONFLICTS_HIDE_LOADER":
            return { ...state, conflictLoader: false };
        case "CONFLICTS_LIST":
            return { ...state, conflictList: action.payload };
        case "CONFLICTS_RESPONSE":
            return { ...state, conflictResp: action.payload };
        case "SET_FARMER_DATA":
            return { ...state, farmerData: action.payload };
        default:
            return state;
    }
};
