import { IssuesCloseOutlined } from '@ant-design/icons'
import { Auth } from 'aws-amplify'
import { ToWords } from 'to-words';
import React from 'react'
import Request from './request';

const toWords = new ToWords();

export const cognitoRegister = async (valData) => {
    let attributes = {
        email: valData.email,
        "custom:userType": valData.userType,
        "custom:name": valData.name,
        "custom:mobile": valData.mobile.toString(),
        "custom:companyId": valData.companyId,
        "custom:id": valData.id
    }
    if (valData.suspentionTime) {
        attributes["custom:suspentionTime"] = valData.suspentionTime
    }
    if (valData.mcpGroups) {
        attributes["custom:mcpGroups"] = valData.mcpGroups.join(',')
    }
    if (!valData.mcpGroups)
        valData.mcpGroups = []
    try {
        const newUser = await Auth.signUp({
            username: valData.email,
            password: valData.password,
            attributes
        });
        return ({ error: false, success: true, data: newUser })
    } catch (e) {
        return ({ error: true, success: false, err: e, message: e.message })
    }
}

export const formatInr = (num) => {
    if(!num || isNaN(num)) return num
    return num.toLocaleString('en-IN');
}

export const convertToWords = (num) => {
    if(!num) return ''
    if(isNaN(num)) return num
    return <p
     style={{fontSize:'10px', fontWeight:'bold', textTransform:'capitalize'}}
     >
         {toWords.convert(num, { currency: true, ignoreDecimal: true })}
    </p>


}

export const  scannerOptions =[
    {field: 'fat', fieldMin: 3, fieldMax: 10, disabled: true, show: true},
    {field: 'snf', fieldMin: 3, fieldMax: 12, disabled: false, show: true},
    {field: 'clr', fieldMin: 2, fieldMax: 4, disabled: false, show: false},
    {field: 'density', fieldMin: 2, fieldMax: 4, disabled: false, show: false},
    {field: 'lactose', fieldMin: 2, fieldMax: 4, disabled: false},
    {field: 'salts', fieldMin: 2, fieldMax: 4, disabled: false},
    {field: 'protein', fieldMin: 2, fieldMax: 4, disabled: false},
    {field: 'temp', fieldMin: 20, fieldMax: 88, disabled: false},
    {field: 'addedWater', fieldMin: 40, fieldMax: 100, disabled: false},
    {field: 'freezingPoint', fieldMin: 2, fieldMax: 4, disabled: false},
    {field: 'ph', fieldMin: 5.5, fieldMax: 8, disabled: false},
    {field: 'conductivity', fieldMin: 6, fieldMax: 12, disabled: false},
]

export const getLatestSettings = async(data) =>{
    let id = data;
    if(!id){
        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if(!user || !user.companyId) return;
        id = user.companyId
    }
    let {data:settingData} = await Request.getSetting(id);
    if(settingData)
    localStorage.setItem('measurement',settingData.measurement || 'Lt')

}
