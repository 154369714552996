import React from 'react'
import { Route, Link, Redirect } from 'react-router-dom'

import styles from './login.less'
import {
    Form, Card, Input, Button, notification, message
} from 'antd'
import { UserOutlined, LockFilled } from '@ant-design/icons';
import { connect, useSelector, useDispatch } from 'react-redux'

import axios from "axios";
import { getToken } from "../../../request";
import { forgotPasswordUrl, resetPasswordUrl } from "../api/loginUrls";
import { push } from 'connected-react-router'
import Request from '../../../request'


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            sending: false
        }
        this.sendCode = this.sendCode.bind(this);
    }


    componentDidMount = () => {
        // this.props.dispatch(hideLoader())
    }
    async sendCode() {
        this.props.form.validateFields(
            ["email"],
            async (err, values) => {
            if (!err) {
                this.setState({ sending: true });
                let data = this.props.form.getFieldValue('email')
                await axios
                    .get(forgotPasswordUrl(), { params: { data }, ...getToken() })
                    .then(({ data }) => {
                        this.setState({ sending: false })
                        if (!data.error) {
                            message.info('A verification code is sent on your email address and phone number.', 10)
                            this.setState({ showCode: true })
                        }
                        else {
                            notification.error({
                                message: 'OTP send failed',
                                description: data.message
                            })
                        }
                        // this.props.form.resetFields();
                    })
            }
        })
    }

    verifyCode = (e) => {
        e.preventDefault()
        this.props.form.validateFields(
            ['email','code','password','confirmPassword'],
            async (err, values) => {
            if (!err) {
                if(!values.email || !values.code || !values.password || !values.confirmPassword)
                {
                    return;
                }
                console.log("no err")
                const { email, code, password, confirmPassword } = values
                if(password !== confirmPassword){
                    notification.warning({ message: 'Passwords donot match.' });
                    return;
                }
                let data = { email, otp: code, newPassword: password }
                this.setState({ loading: true })
                Request.verifyOtpAndReset(data)
                    .then(data => {
                        this.setState({ loading: false })
                        if (data.success) {
                            message.success('Password successfully changed', 5)
                             this.props.form.resetFields();
                            this.props.dispatch(push('/'))
                        }
                        else {
                            notification.error({ message: 'Incorrect OTP' })
                        }
                    })
            }
        })
    }
    render() {

        const { getFieldDecorator } = this.props.form
        const { loading, sending, showCode } = this.state


        return (
            <Card
                bordered={false} bodyStyle={{ display: 'flex' }}>
                {/* Forgot Password Screen */}
                <div className={styles.transition1} >
                    <Form onSubmit={this.verifyCode}>
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [ {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                  },
                                  { required: true, message: 'Please enter your email address!' },
                                 ]
                            })(
                                <Input
                                    disabled={showCode}
                                    prefix={
                                        <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                    }
                                    placeholder="Email address" />
                            )}
                        </Form.Item>
                        {showCode && <>
                            <Form.Item>
                                {getFieldDecorator('code', {
                                    rules: [{ required: true, message: 'Please enter your verification code' }]
                                })(
                                    <Input placeholder="6-digit Code" maxLength={6} minLength={6} />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }]
                                })(
                                    <Input.Password prefix={
                                        <LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />
                                    } visibilityToggle
                                        placeholder="New Password" />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('confirmPassword', {
                                    rules: [{ required: true, message: 'Please confirm your Password!' }]
                                })(
                                    <Input.Password prefix={
                                        <LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />
                                    } visibilityToggle
                                        placeholder="Confirm New Password" />
                                )}
                            </Form.Item>
                        </>}
                        {!showCode ?
                            <Form.Item >
                                <Button type='primary'
                                    loading={sending}
                                    onClick={this.sendCode} className={styles.btn} >Submit</Button>
                            </Form.Item> :
                            <Form.Item >
                                <Button type='primary' htmlType='submit'
                                    loading={loading}
                                    className={styles.btn} >Submit</Button>
                                <Button type='secondary'
                                    onClick={this.sendCode}
                                    loading={sending}
                                    className={styles.btn} >Resend</Button>
                            </Form.Item>
                        }
                    </Form>
                </div>
            </Card>
        )
    }
}

const WrappedNormalForgotPassForm = Form.create()(ForgotPassword)

const mapStateToProps = ({ counter, global }) => ({
    count: counter.count,
    loading: global.buttonLoading,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedNormalForgotPassForm)
