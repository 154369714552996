import { Input, Icon, AutoComplete } from 'antd'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import useArrowKeyNavigationHook from 'react-arrow-key-navigation-hook'

import styles from './styles.less'
import { useSelector, useDispatch } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import { getMenuArr, getMenuItem } from './searchUtils'
import _ from 'lodash'
import { isUrl } from '../_utils/utils'
import Request from '../../request'
import { getPushPathWrapper } from '../../routes'
import { push } from 'connected-react-router'

const Modal = () => {
  const dispatch = useDispatch()
  const modalRef = useRef(null)
  const inputRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [menuList, setMenuList] = useState([])
  const [farmerMenu, setFarmerMenu] = useState([])
  const [mcpMenu, setMcpMenu] = useState([])
  const [filteredMenuList, setFilteredMenuList] = useState([])
  const [searchMenu, setSearchMenu] = useState([])

  const { showGlobalSearchBar, currentUser } = useSelector((state) => ({
    showGlobalSearchBar: state.global.showGlobalSearchBar,
    currentUser: state.global.currentUser
  }))

  const closeGlobalsearch = () => {
    dispatch({ type: 'HIDE_GLOBAL_SEARCH_BAR' })
  }

  const resetGlobalSearch = () => {
    setSearchText('')
    setFilteredMenuList(() => [])
    setSearchMenu(() => [])
  }

  const handleOuterModalClick = (e) => {
    e && e.stopPropagation()
    closeGlobalsearch()
    resetGlobalSearch()
  }

  const handleModalClick = (e) => {
    e && e.stopPropagation()
  }

  const fetchMenu = () => {

    let menuArr = getMenuArr({ userType: currentUser && currentUser.userType })

    setMenuList(menuArr)
    setFilteredMenuList([...menuArr])
    setSearchMenu([...menuArr])

  }

  const filterMenu = () => {
    let filtered = []
    if (searchText) {
      filtered = menuList.filter((item) => {
        return item.name.toLowerCase().includes(searchText.toLowerCase())
      })
    }
    filtered = _.uniqBy(filtered, 'name')
    filtered = _.sortBy(filtered, [
      function(o) {
        return o.name
      }
    ])
    setFilteredMenuList([...filtered])
    setSearchMenu([...filtered])

  }

  const getIcon = (icon) => {
    if (typeof icon === 'string' && isUrl(icon)) {
      return <img src={icon} alt="icon" className={styles.icon}/>
    }
    if (typeof icon === 'string') {
      return <Icon className={styles.icon} type={icon}/>
    }
    return icon
  }

  const handleEscape = useCallback((event) => {
    if (event.key == 'Escape') {
      if (showGlobalSearchBar) {
        dispatch({ type: 'HIDE_GLOBAL_SEARCH_BAR' })
      }
    }
  }, [])

  const buildFarmerMenu = (farmers) => {
    farmers = farmers.map((item) => {
      return {
        name: item.farmerName,
        menuName: item.farmerName,
        phone: item.phone,
        balance: item.netBalance,
        id: item.id || item.farmerId,
        key: item.farmerId,
        farmerId: item.farmerId,
        mcpId: item.mcpId.id,
        type: 'farmers'
      }
    })
    setFarmerMenu([...farmers])
  }

  const buildMcpMenu = (mcp) => {
    mcp = mcp.map((item) => {
      return {
        type: 'mcp',
        name: item.name,
        menuName: item.name,
        id: item.id,
        key: item.id || item.mcpId,
        mcpId: item.mcpId,
        mcpGroup: item.mcpGroupId && item.mcpGroupId.groupName,
        contactName: item.contactName
      }
    })
    setMcpMenu([...mcp])
  }

  const handleFarmerClick = (record) => {
    let obj = {
      farmerUUID: record.id,
      mcpId: record.mcpId
    }
    dispatch(getPushPathWrapper('farmer.unbilledcollectionsFarmer', obj))
    closeGlobalsearch()
  }

  const updateMenuList = () => {
    let menuArr = [...filteredMenuList, ...farmerMenu, ...mcpMenu]
    setSearchMenu(menuArr)
  }

  useEffect(() => {
    updateMenuList()
  }, [farmerMenu, mcpMenu])

  const fetchFarmers = async () => {
    if (!searchText) return
    let filters = {
      farmerName: [searchText],
      page: 1,
      results: 20,
      companyId: currentUser.companyId,
      regExFilters: ['farmerName']
    }
    let { data: farmers } = await Request.getAllFarmers(filters)
    if (!farmers.length) setFarmerMenu([])
    else buildFarmerMenu(farmers)
  }

  const fetchMcps = async () => {
    if (!searchText) return
    let filters = {
      name: [searchText],
      page: 1,
      results: 20,
      companyId: currentUser.companyId,
      regExFilters: ['name']
    }
    let { data: mcps } = await Request.getAllMcp(filters)
    if (!mcps.length) setMcpMenu([])
    else buildMcpMenu(mcps)
  }

  useEffect(() => {
    fetchMenu()
    closeGlobalsearch()
    document.addEventListener('keydown', handleEscape, false)

    setTimeout(() => {
      filterMenu()

    }, 400)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [])

  useEffect(() => {
    filterMenu()
    fetchFarmers()
    fetchMcps()

    // focusFxn()

  }, [searchText])

  useEffect(() => {
    if (showGlobalSearchBar) {
      /*inputRef &&
      inputRef.current.focus({
        cursor: 'start'
      })*/
      modalRef.current.classList.add(styles.visible)
      inputRef.current && inputRef.current.focus()

    } else {
      modalRef.current.classList.remove(styles.visible)
    }
  }, [showGlobalSearchBar])

  const handleMenuSelect = (item) => {
    resetGlobalSearch()
    closeGlobalsearch()
    if (item.type == 'menu') {
      dispatch(push(item.path))
    } else if (item.type == 'farmers') {
      let obj = {
        farmerUUID: item.id,
        mcpId: item.mcpId
      }
      dispatch(getPushPathWrapper('farmer.unbilledcollectionsFarmer', obj))
    } else if (item.type == 'mcp') {
      dispatch(getPushPathWrapper('mcp.editMcc', { id: item.id }))
    }
  }

  function scrollList () {
    setTimeout(() => {
      //  var list = document.getElementById('list'); // targets the <ul>
      // console.log("list",list)
      /* var first = list.firstChild; // targets the first <li>
       var maininput = document.getElementById('input');  // targets the input, which triggers the functions populating the list
       document.onkeydown = function(e) { // listen to keyboard events
         switch (e.keyCode) {
           case 38: // if the UP key is pressed
             if (document.activeElement == (maininput || first)) { break; } // stop the script if the focus is on the input or first element
             else { document.activeElement.parentNode.previousSibling.firstChild.focus(); } // select the element before the current, and focus it
             break;
           case 40: // if the DOWN key is pressed
             if (document.activeElement == maininput) { first.firstChild.focus(); } // if the currently focused element is the main input --> focus the first <li>
             else { document.activeElement.parentNode.nextSibling.firstChild.focus(); } // target the currently focused element -> <a>, go up a node -> <li>, select the next node, go down a node and focus it
             break;
         }
       }*/
    }, 3000)

  }

  // scrollList()

  const parentRef = useArrowKeyNavigationHook({ selectors: 'a,button,input' })

  return (
    <React.Fragment>
      <div
        ref={modalRef}
        className={`${styles.modal}`}
        onClick={(e) => handleOuterModalClick(e)}>
        <div
          ref={parentRef  }
          onClick={(e) => handleModalClick(e)}
          className={styles.modal__wrap}>
          <div style={{ display: 'flex', padding: '0px 8px' }}>
            <SearchOutlined className={styles.searchIcon}/>
            <Input
              ref={inputRef}
              className={styles.searchBar}
              value={searchText}
              id="input"

              placeholder={'Search..'}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </div>

          <ul id="searchList" className={styles.menuListwrap}>
            {searchMenu.map((item, i) => {
              return (
                <li
                  className="listElement"
                  key={i}
                  onClick={() => handleMenuSelect(item)}
                >
                  <a href="#" className={styles.anchrr} tabIndex="1">
                    {getMenuItem(item)}
                  </a>
                </li>
              )
            })}
          </ul>

        </div>
      </div>
    </React.Fragment>
  )
}

export default Modal
