import axios from 'axios'
import {
  dashboardCollectionsListUrl,
  dashboardAveragesUrl,
  dashboardSummaryUrl,
  offlineMcps,
  companyStatusUrl,
  dashboardCollectionLineGraphUrl, dashboardfatSnfLineGraphUrl, dashboardCleaningUrl
} from '../api/dashboardUrl'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { getToken } from '../../../request'

export const CollectionListAction = (inputData) => async (dispatch) => {

  await axios
    .get(dashboardCollectionsListUrl(), { params: { ...inputData }, ...getToken() })
    .then(function({ data }) {


        dispatch({ type: 'COLLECTION_LIST', payload: data })
      }
    )
    .catch(function(error) {
      // toast.error(JSON.stringify(error));
      console.log(error)
    })
}
export const AveragesAction = (inputData) => async (dispatch) => {
  await axios
    .get(dashboardAveragesUrl(), { params: { ...inputData }, ...getToken() })
    .then(function({ data }) {

      dispatch({
        type: 'COLLECTION_AVERAGE_RESPONSE',
        payload: data
      })
    })
    .catch(function(error) {
      // toast.error(JSON.stringify(error));
      console.log(error)
    })
}
export const SummaryAction = (inputData) => async (dispatch) => {
  await axios
    .get(dashboardSummaryUrl(), { params: { ...inputData }, ...getToken() })
    .then(function({ data }) {

      dispatch({
        type: 'COLLECTION_SUMMARY_RESPONSE',
        payload: data
      })
    })
    .catch(function(error) {
      // toast.error(JSON.stringify(error));
      console.log(error)
    })
}
export const NonActiveMcp = (id, data) => async (dispatch) => {
  await axios
    .get(offlineMcps(id), { ...getToken(), params: { ...data } })
    .then(function({ data }) {

      dispatch({
        type: 'OFFLINE_MCP',
        payload: data
      })
    })
    .catch(function(error) {
      // toast.error(JSON.stringify(error));
      console.log(error)
    })
}
export const CompanyStatusAction = (data) => async (dispatch) => {
  await axios
    .get(companyStatusUrl(), { params: { ...data }, ...getToken() })
    .then(function({ data }) {
      dispatch({
        type: 'COMPANY_STATUS',
        payload: data
      })
    })
    .catch(function(error) {
      // toast.error(JSON.stringify(error));
      console.log(error)
    })

}

export const DashboardCollectionLineGraphAction = (data) => async (dispatch) => {
  await axios
    .post(dashboardCollectionLineGraphUrl(), data, getToken())
    .then(function({ data }) {
      dispatch({
        type: 'DASHBOARD_LINE_GRAPH',
        payload: data
      })
    })
    .catch(function(error) {

      console.log(error)
    })
}

export const DashboardFatSnfLineGraphAction = (data) => async (dispatch) => {
  await axios
    .post(dashboardfatSnfLineGraphUrl(), data, getToken())
    .then(function({ data }) {
      dispatch({
        type: 'DASHBOARD_FAT_SNF_GRAPH',
        payload: data
      })
    })
    .catch(function(error) {

      console.log(error)
    })
}

export const DashboardCleaningAction = (params) => async (dispatch) => {
  await axios
    .get(dashboardCleaningUrl(), { params: { ...params }, ...getToken() })
    .then(function({ data }) {
      // const { success, data: arr } = data
      dispatch({
        type: 'DASHBOARD_CLEANING_LOGS',
        payload: data ? data : []
      })
      //  if (success) {
      //    dispatch({
      //      type: 'DASHBOARD_CLEANING_LOGS',
      //      payload: data
      //    })
      //  } else {
      //    dispatch({
      //      type: 'DASHBOARD_CLEANING_LOGS',
      //      payload: []
      //    })
      //  }

    })
    .catch(function(error) {

      console.log(error)
    })
}
