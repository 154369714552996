import React, { Component, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import {
    Divider,
    InputNumber,
    notification,
    Drawer,
    DatePicker,
    Form,
    Card,
    Input, Button, message
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import async from 'async'
import { push } from 'connected-react-router'
import styles from './styles.less'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { hideLoader, showLoader } from '../../../modules/actions'
import { ReCaptcha } from 'react-recaptcha-google'
import { apiUrl, SITEKEY } from '../../../settings'
import { Auth } from 'aws-amplify'
import { sendOtpAction, verifyOtpAction, register, registerWithCognito } from '../actions/registerActions'

const Register = (props) => {
    const dispatch = useDispatch()
    const captchaDemoRef = useRef(null)
    const captchaDemo = captchaDemoRef.current

    const [state, setState] = useState({
        loading: false,
        anchorFlag: true,
        loader: false,
        register: false,
        cardLoading: true
    })
    const { otpVerified, disableNumber, disableOtpSend, loading, verificationLoader, otpSendLoader } = useSelector(state => ({
        otpVerified: state.register.otpVerified,
        loading: state.register.registerLoader,
        verificationLoader: state.register.verificationLoader,
        otpSendLoader: state.register.otpSendLoader,
        disableNumber: state.register.disableNumber,
        disableOtpSend: state.register.disableOtpSend
    }))
    useEffect(() => {
        dispatch({ type: 'SET_DISABLE_NUMBER', payload: false })
        dispatch({ type: 'SET_DISABLE_OTP_SEND', payload: false })
        dispatch({ type: 'SET_OTP_VERIFIED', payload: false });
        dispatch({ type: 'OTP_SEND_HIDE_LOADER' });
        dispatch({ type: 'REGISTER_HIDE_LOADER' });
        if (captchaDemo) {
            captchaDemo.reset();
            captchaDemo.execute();
        }
        setState({
            ...state,
            cardLoading: false
        })
    }, [])

    const onFinish = () => {
        const type = process.env.REACT_APP_AUTH_TYPE
        const { recaptchaToken } = state

        switch (type) {
            case 'COGNITO':
                return (async (err, values) => {
                    if (!err) {
                        values.smsSenderId = 'HTAMCU'
                        dispatch(registerWithCognito(values, recaptchaToken))
                        onLoadRecaptcha()
                    }
                })
                break;
            default:
                return (async (err, values) => {
                    if (!err) {
                        values.smsSenderId = 'HTAMCU'
                        dispatch(register(values, recaptchaToken))
                        onLoadRecaptcha()
                    }
                })
                break;
        }
    }

    const handleSubmit = (e) => {
        let mobileNumber = props.form.getFieldValue('mobile')
        e.preventDefault()
        if (!mobileNumber || !otpVerified) {
            return
        }

        props.form.validateFields(onFinish())

    }
    const setUser = (x) => {
        localStorage.setItem('token', x.token)
        localStorage.setItem('user', JSON.stringify(x.user))
        dispatch({
            type: 'SET_CURRENT_USER',
            user: x.user
        })

        setTimeout(() => {
            dispatch(push('/'))
        }, 300)
    }

    const validateField = (length, number) => {
        if (number.length !== length || isNaN(parseInt(number))) {
            return false
        }
        return true
    }
    const sendOtp = () => {
        let mobileNumber = props.form.getFieldValue('mobile') || ''

        if (!validateField(10, mobileNumber)) {
            return notification.error({ message: 'Invalid Mobile Number' })
        }
        dispatch(sendOtpAction(mobileNumber))
        setState({ ...state, mobileNumber })
    }
    const verifyOtp = () => {
        let otp = props.form.getFieldValue('otp') || ''

        if (!validateField(6, otp)) {
            return notification.error({ message: 'Invalid OTP' })
        }
        dispatch(verifyOtpAction({ mobileNumber: state.mobileNumber, otp }))
    }

    const onLoadRecaptcha = () => {
        if (captchaDemo) {
            captchaDemo.reset();
            captchaDemo.execute();
        }
    }
    const verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!! 
        setState({
            ...state,
            recaptchaToken
        })
    }
    const { getFieldDecorator, getFieldValue } = props.form
    const { cardLoading } = state
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
            md: { span: 10 }
        },
        wrapperCol: {
            xs: { span: 18 },
            sm: { span: 10 },
            md: { span: 6 }
        }
    }

    const required = {
        rules: [{
            required: true,
            message: 'Field Required'
        }]
    }
    return (
        <Card loading={cardLoading} bordered={false}>
            slfjsl
            <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
                <ReCaptcha
                    ref={captchaDemoRef}
                    size="invisible"
                    render="explicit"
                    sitekey={SITEKEY}
                    onloadCallback={onLoadRecaptcha}
                    verifyCallback={verifyCallback}
                />
                <Form.Item label='Mobile No.'>
                    <div className={styles.inlineForm}>
                        {getFieldDecorator('mobile', required)(
                            <Input style={{ flex: 1 }} disabled={disableNumber} placeholder='Please Enter Mobile No.' />
                        )}
                        <Button id='sendOtp' className={styles.cancel} type='primary' loading={otpSendLoader} disabled={disableOtpSend}
                            onClick={sendOtp}>Send OTP</Button>
                        {disableNumber && <a id='editNumber' className={styles.cancel} onClick={(e) => {
                            e.preventDefault()
                            dispatch({ type: 'SET_DISABLE_NUMBER', payload: false })
                            dispatch({ type: 'SET_DISABLE_OTP_SEND', payload: false })
                            dispatch({ type: 'SET_OTP_VERIFIED', payload: false });
                            setState({ ...state, mobileNumber: null })
                        }}>Edit Number?</a>}
                    </div>
                </Form.Item>
                {disableOtpSend && !otpVerified && <Form.Item label='OTP'>
                    <div className={styles.inlineForm}>
                        {getFieldDecorator('otp', required)(
                            <Input style={{ flex: 1 }} placeholder='OTP' />
                        )}
                        <Button id='verifyOtp' className={styles.cancel} type='primary' loading={verificationLoader} onClick={verifyOtp}>Verify
                OTP</Button>
                    </div>
                </Form.Item>}
                {otpVerified && <>
                    <Divider />
                    <Form.Item label='Your Name'>{getFieldDecorator('contactPerson', required)(
                        <Input placeholder='Full Name' />
                    )}</Form.Item>
                    <Form.Item label='Company Name'>{getFieldDecorator('name')(
                        <Input placeholder='Company Name' />
                    )}</Form.Item>
                    <Form.Item label='Address'>{getFieldDecorator('address', required)(
                        <Input.TextArea placeholder='address' />
                    )}</Form.Item>
                    <Form.Item label='State'>{getFieldDecorator('state', required)(
                        <Input placeholder='State' />
                    )}</Form.Item>
                    <Form.Item label='City'>{getFieldDecorator('city', required)(
                        <Input placeholder='city' />
                    )}</Form.Item>
                    <Form.Item label='Pin Code'>{getFieldDecorator('pincode', required)(
                        <InputNumber placeholder='Pin Code' />
                    )}</Form.Item>
                    <Form.Item label='GST NO.'>{getFieldDecorator('gstNo')(
                        <Input placeholder='GST No.' />
                    )}</Form.Item>
                    <Form.Item label='E-Mail'>{getFieldDecorator('email', required)(
                        <Input type='email' placeholder='E-mail id' />
                    )}</Form.Item>
                    <Form.Item label='Password'>{getFieldDecorator('password', required)(
                        <Input.Password visibilityToggle={true} placeholder='Password' />
                    )}</Form.Item>

                    <div className={styles.btns}>
                        <Button id='register' type="primary" htmlType="submit" loading={loading}>
                            Register
              </Button>
                    </div>
                </>}
                <div className={styles.btns}>
                    <a id='login' className={styles.cancel} onClick={(e) => {
                        e.preventDefault()
                        dispatch(push('/login'))
                    }
                    }>
                        Already have an account?
            </a>
                </div>
            </Form>
        </Card>
    )
}

const wrapperRegister = Form.create()(Register)

export default wrapperRegister
