import React from 'react'

import styles from './styles.less'
import {
    Form, Card, Input, Button, notification, message
} from 'antd'
import { UserOutlined, LockFilled } from '@ant-design/icons'
import {  hideLoader } from '../../modules/actions'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import Auth from '@aws-amplify/auth';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount = () => {
        this.props.dispatch(hideLoader())
    }
    sendCode = () => {
        this.setState({ sending: true })
        let email = this.props.form.getFieldValue('email')
        Auth.forgotPassword(email)
            .then(data => {
                message.info('A verification code is sent on your email address', 10)
                this.setState({ showCode: true, sending: false })
            })
            .catch(err => {
                this.setState({ sending: false })
                notification.error({ message: err.message })
            })
    }
    verifyCode = (e) => {
        const { dispatch, sessionId } = this.props
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { email, code, password } = values
                this.setState({ loading: true })
                Auth.forgotPasswordSubmit(email, code, password)
                    .then(data => {
                        this.setState({ loading: false })
                        console.log(data)
                        message.success('Password successfully changed', 5)
                        this.props.dispatch(push('/login'))
                    })
                    .catch(err => {
                        this.setState({ loading: false })
                        console.log(err)
                        notification.error({ message: err.message })

                    })

            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const { flag, anchorFlag, loading, sending, cardLoading, showCode } = this.state

        return (
            <Card loading={cardLoading} bordered={false} bodyStyle={{ display: 'flex' }}>
                {/* Forgot Password Screen */}
                <div className={styles.transition} >
                    <Form onSubmit={this.verifyCode}>
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please enter your email address!' }]
                            })(
                                <Input disabled={showCode} prefix={
                                    <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                }
                                    placeholder="Email address" />
                            )}
                        </Form.Item>
                        {showCode && <>
                            <Form.Item>
                                {getFieldDecorator('code', {
                                    rules: [{ required: true, message: 'Please enter your verification code' }]
                                })(
                                    <Input placeholder="6-digit Code" maxLength={6} minLength={6} />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }]
                                })(
                                    <Input.Password prefix={
                                        <LockFilled style={{ color: 'rgba(0,0,0,.25)' }} />
                                    } visibilityToggle
                                        placeholder="Password" />
                                )}
                            </Form.Item>
                        </>}
                        {!showCode ? <Form.Item >
                            <Button type='primary' loading={sending} onClick={this.sendCode} className={styles.btn} >Submit</Button>
                        </Form.Item> :
                            <Form.Item >
                                <Button type='primary' htmlType='submit' loading={loading} className={styles.btn} >Submit</Button>
                                <Button type='secondary' loading={sending} className={styles.btn} >Resend</Button>
                            </Form.Item>}
                    </Form>
                </div>
            </Card>
        )
    }
}

const WrappedNormalForgotPassForm = Form.create()(ForgotPassword)

const mapStateToProps = ({ counter, global }) => ({
    count: counter.count,
    loading: global.buttonLoading,
    sessionId: global.otpSession,
    sessionId: global.sessionId
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedNormalForgotPassForm)
