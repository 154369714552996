import React, { PureComponent, useState, useEffect, useRef } from 'react'
import socketIOClient from 'socket.io-client'
import { timeStampFormat, socketUrl, dateFormat, apiUrl } from '../../../settings'
import {
  Table,
  Select,
  Tag, Row, Col,
  Tooltip,
  Avatar, Button
} from 'antd'
import { CollectionListAction, LastInsertedAction } from '../action/dashboardAction'
import { connect, useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import styles from './styles.less'
import { priceformatter } from '../../../components/_utils/utils'
import _ from 'lodash';
import Request from '../../../request';
import { connectSocket, subscribeToChat } from '../../../socket';
import VisibilitySensor from 'react-visibility-sensor'
import { getPushPathWrapper } from '../../../routes'

const { Option } = Select
let unit = localStorage.getItem('measurement') || ''

function LiveCollections(props) {


  const dispatch = useDispatch();

  const [arr, setArr] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);



  useEffect(() => {

    const { currentUser } = props;

    connectSocket(currentUser.companyId)

    subscribeToChat((err, data) => {

      if (err) return;
      if (data && !isNaN(data.totalAmount)) {
        setArr((prevState) => {
          if (prevState) {
            let isNew = false;

            _.each(prevState, (val) => {
              if (moment(val.collectionDate).format('DD-MM-YYYY') != moment(data.collectionDate).format('DD-MM-YYYY')) {
                isNew = true;
              }
            })
            if (isNew) {
              apiRequest({ page: 1 });
              dispatch({ type: 'REFRESH_BOX', payload: new Date() })
              return []

            } else {
              dispatch({ type: 'REFRESH_BOX', payload: new Date() })
              setTotal((total) => total + 1)
              return [data, ...prevState]
              // }

            }


          }
        });


      }

    });

    let timer1 = setInterval(() => {
      //  apiRequest()
    }, 60000)


    return () => {
      clearInterval(timer1)
    }
  }, [])


  const getIdsFromCurrentList = async () => {
    let ids = arr.map((item) => { return item.id })
    return ids;

  }

  const filterDuplicateData = async (data) => {
    let ids = await getIdsFromCurrentList();
    let idObj = {};
    for (let i = 0; i < ids.length; i++) {
      idObj[`${ids[i]}`] = true;
    };
    let filteredData = data.filter((item) => {
      return (idObj[`${item.id}`] != true)
    });
    return filteredData;
  }

  // }
  let apiRequest = async (params, columns) => {

    setLoadMoreLoader(true);
    if (!params) params = {}
    const { currentUser } = props
    if (params && !params.sortField) {
      params.sortField = 'syncTime'
      params.sortOrder = 'descend'
    }
    // TODO: change results to 100
    if (!params.page) params.page = page;
    if (!params.results) params.results = 100
    params.count = params.results
    params.sortField = 'syncTime'
    params.sortOrder = 'descend'

    let filter = {
      ...params,
      companyId: currentUser.companyId,
    }

    if (currentUser.userType === 'manager')
      filter.mcpGroupId = currentUser.mcpGroups


    let { success, data, total } = await Request.getDashboardLastData(filter)
    setLoadMoreLoader(false);

    if (success && data.length) {
      let filteredData = await filterDuplicateData(data);
      if (filteredData.length == 0) {
        loadMoreCollections();
        return;
      }
      let newData = [];
      if (params.page == 1) {
        newData = [...filteredData]
      } else {
        newData = [...arr, ...filteredData];
      }
      setArr(() => newData);
      setTotal(total);
    }
    if (data && data.length > 0) {
      setPage(() => params.page)
    }

  }

  const handleChange = (value) => {
    localStorage.setItem('liveCollection', JSON.stringify(value))
    apiRequest()
  }
  const loadMoreCollections = () => {
    let nextPage = page + 1;
    apiRequest({ page: nextPage })
  }

  let columns = [
    {
      title: 'S. No.',
      key: 's_no',
      width: '40px',
      render: (val, record, key) => {
        return <>{key + 1}</>
      },
    },
    {
      title: 'Farmer Name (Id)',
      key: 'farmerName',
      width: '100px',
      dataIndex: 'farmerName',
      render: (val, record) => {
        return <Tooltip title={record.farmerName + '(' + record.farmerId + ')'}>
          <div className={styles.ellipses} onClick={()=>{
            console.log("record is", record)
             let obj = {
              farmerUUID: record.farmerUUID,
              mcpId: record.mcpId,
            }
            dispatch(
              getPushPathWrapper(
                'farmer.unbilledcollectionsFarmer',
                obj
              )
            )
          }}>
            <a>
            {record.farmerName + '(' + record.farmerId + ')'}
            </a>
          </div>
        </Tooltip>
      }
    },
    {
      title: 'Type',
      key: 'milkType',
      dataIndex: 'milkType',
      width: '40px',
      render: (val, record) => {
        return <Tooltip title={val}>
          <div className={styles.ellipses}>
            {val}
          </div>
        </Tooltip>
      }

    },
    {
      title: 'Mode',
      key: 'collectionMode',
      dataIndex: 'collectionMode',
      width: '40px',
      render: (val, record) => {
        return (val && val.toUpperCase() == 'MANUAL' ?
          <Avatar size="small" style={{ backgroundColor: '#EE9749' }}>M</Avatar> :
          <Avatar style={{ backgroundColor: '#8BC34A' }} size="small">A</Avatar>
        )
      }
    },
    {
      title: 'MCP',
      key: 'mcpId',
      width: '80px',
      dataIndex: 'mcpName',
      render: (val, record) => {
        return (
          <Tooltip title={val}>
            <div className={styles.ellipses}>
              {val}
            </div>
          </Tooltip>)
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '100px',
      dataIndex: 'status',
      render: (val, record) => {
        return (val == 'ACCEPTED' ? <div className={styles.accepted}>
          {val}
        </div> : <div className={styles.rejected}>
            {val}
          </div>)
      }
    },
    {
      title: 'FAT',
      key: 'fat',
      width: '40px',
      dataIndex: 'fat',
      render: (val) => `${val}%`
    },
    {
      title: 'SNF',
      key: 'snf',
      width: '50px',
      dataIndex: 'snf',
      render: (val) => `${val}%`
    },
    {
      title: 'Ad.W.',
      key: 'addedWater',
      width: '50px',
      dataIndex: 'addedWater',
      render: (val) => `${val || 0}%`
    },
    {
      title: 'Qty.',
      width: '50px',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (val) => {
        return `${priceformatter(val)} ${unit}`
      }
    },
    // {
    //   title: 'Rate',
    //   width: '60px',
    //   key: 'rate',
    //   dataIndex: 'rate',
    //   render: (val) => `₹${val}`
    // },
    {
      title: 'Amount',
      width: '70px',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      render: (val) => `₹${priceformatter(val)}`
    },
    {
      title: 'Rate/Lt',
      key: 'ratePerLitre',
      width: '60px',
      dataIndex: 'ratePerLitre',
      render: (val) => `₹${val || 0}`
    },
    {
      title: 'Sync Time',
      key: 'syncTime',
      width: '100px',
      dataIndex: 'syncTime',
      render: date => {
        return (
          <Tooltip title={moment(date).format(timeStampFormat)}>
            {date && moment(date).fromNow()}
          </Tooltip>
        )
      }
    }
  ]
  const table = useRef(null)
  const columnsName = _.map(columns, x => ({ key: x.key, title: x.title }))
  let all = []

  _.each(columns, x => {
    all.push(x.key)
  })

  let def = localStorage.getItem('liveCollection')
  if (def) {
    all = JSON.parse(def)
  }
  columns = _.filter(columns, (x) => {
    return all.indexOf(x.key) !== -1
  })



  return (
    <div className={styles.liveCollection}>
      <Row>
        <Col span={12}>
          <h2>
            <div>Live Milk Collections: {total} </div>
          </h2>
        </Col>


        <Col span={8}>
          <div className={styles.collectionDate}>{arr[0] && `Collection Date : ${moment(arr[0].collectionDate).format('DD-MM-YYYY')}`}</div>
        </Col>
        <Col span={4}>
          <div className={styles.collectionDate}>
            <Select
              size='small'
              mode="multiple"
              maxTagTextLength={10}
              maxTagCount={0}
              style={{}}
              placeholder="select columns"
              defaultValue={all}
              onChange={handleChange}
              optionLabelProp="label"
            >

              {columnsName.map((x) => (<Option value={x.key} label={x.title}>
                {x.title}
              </Option>))}

            </Select>

          </div>

        </Col>
      </Row>





      {/* <div style={{ textAlign: 'right', marginBottom: '13px' }}>

      </div> */}

      <Table
        id={'liveCollection'}
        showSelector={true}
        size="small"
        columns={columns}
        ref={table}
        bordered
        dataSource={arr}
        pagination={false}
        scroll={{ y: 710 }}
      />
      <VisibilitySensor
        partialVisibility onChange={(isVisible) => {
          if (isVisible && !loadMoreLoader) {
            loadMoreCollections()
          }
        }}
      >
        <div style={{ margin: '5px' }}> <Button loading={loadMoreLoader} onClick={() => loadMoreCollections()} size={'small'}>Load More</Button>
        </div>
      </VisibilitySensor>
    </div>)

}
export default LiveCollections
