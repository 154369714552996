import React, { PureComponent, useState, useEffect, useRef } from 'react'
import {
  Select,
  Button,

  DatePicker,

  Row,
  Col,
  Modal,
  Icon,

} from 'antd'
import _ from 'lodash'
import DashboardAverage from './DashboardAverage'
import { connect, useSelector, useDispatch } from 'react-redux'
import styles from './styles.less'
import { timeStampFormat, socketUrl, dateFormat, apiUrl } from '../../../settings'
import WaterWave from 'ant-design-pro/lib/Charts/WaterWave'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import LiveCollection from './collections';
import Summary from './Summary'
import OfflineMcp from './OfflineMcps'
import { CompanyStatusAction } from '../action/dashboardAction'
import { BulbOutlined } from '@ant-design/icons'
import { push } from 'connected-react-router'
const { RangePicker } = DatePicker
const { Option } = Select

// @Form.create()
function Dashboard(props) {

  const [state, setState] = useState({})
  const [summaryArr, setSummary] = useState([])
  const [status, setStatus] = useState(null)
  const [visible, setVisible] = useState(false)
  const table = useRef(null)
  const dispatch = useDispatch()

  const { currentUser, collectionsList, collectionAveragesResp, companyStatus } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    //collectionsList: state.dashboard.collectionsList,
    // collectionAveragesResp: state.dashboard.collectionAveragesResp
    companyStatus: state.dashboard.companyStatus
  })
  )

  useEffect(() => {
    dispatch({ type: 'COMPANY_STATUS', payload: null })
    let filter = { companyId: currentUser.companyId }
    if (currentUser.userType === 'manager')
      filter.mcpGroupId = currentUser.mcpGroups
    dispatch(CompanyStatusAction(filter))
  }, [])

  useEffect(() => {
    if (companyStatus) {
      setStatus(() => ({ ...companyStatus }))
      setVisible(() => companyStatus ? (companyStatus.mcpCount === 0 || companyStatus.farmerCount === 0 || companyStatus.rateChartCount === 0) : false)
    }
    else {
      setVisible(() => false)
      setStatus(() => (null))
    }
  }, [companyStatus])

  const redirectPath = status ? `${status.mcpCount === 0 ? '/mcp/add' : '/farmer/add'}` : ''

  return (
    <div className={styles.wrapper}>


      {/* <Row>
  <Col flex="1"> <LiveCollection currentUser={currentUser} /></Col>
  <Col flex="1">
  <DashboardAverage currentUser={currentUser} />
        <Summary currentUser={currentUser} />
        <OfflineMcp currentUser={currentUser}/>
  </Col>
</Row> */}

      <div className={styles.leftContent}>
        <LiveCollection currentUser={currentUser} />
      </div>
      <div className={styles.rightContent}>
        <DashboardAverage currentUser={currentUser} />
        <Summary currentUser={currentUser} />
        {/*<OfflineMcp currentUser={currentUser} />*/}
      </div>
      {status && <Modal
        title={<div><BulbOutlined /> Tips</div>}
        visible={visible}
        onCancel={() => setVisible(() => false)}
        footer={[
          <Button
            type='primary'
            size='large'
            onClick={() => {
              dispatch(push(redirectPath))
            }}
          >{`Add ${status.mcpCount === 0 ? 'MCP' : 'farmer'}`}</Button>
        ]}
      >
        <p>
          {`Not added any ${status.mcpCount === 0 ? 'milk collection point(MCP)' : 'farmer'} yet.`}
        </p>
        <p>
          Add it right now.
        </p>
      </Modal>}


    </div>
  )

}

export default Dashboard
