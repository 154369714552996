export default (state = {
    companySettingsList: {}
}, action) => {
    switch (action.type) {
        case 'COMPANY_SETTINGS_SHOW_LOADER':
            return { ...state, companyLoader: true };

        case 'COMPANY_SETTINGS_HIDE_LOADER':
            return { ...state, companyLoader: false };

        case 'COMPANY_SETTINGS':
            return { ...state, companySettingsList: action.payload };

        default:
            return state;

    }
};
