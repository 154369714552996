import React, { PureComponent } from 'react'
import { Icon, Alert } from 'antd'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash-decorators'
import styles from './index.less'
import RightContent from './RightContent'
import { dateFormat } from '../../settings'
import moment from 'moment'

export default class GlobalHeader extends PureComponent {
  componentWillUnmount() {
    this.triggerResizeEvent.cancel()
  }

  @debounce(600)
  triggerResizeEvent() {
    // eslint-disable-line
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    window.dispatchEvent(event)
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props
    onCollapse(!collapsed)
    this.triggerResizeEvent()
  }

  render() {
    const { collapsed, isMobile, logo, currentUser } = this.props
    return (
      <div className={isMobile ? styles.mobileHeader : styles.header}>
        {/* {isMobile && (
          <Link to="/" className={styles.logo} key="logo">
            <img src={logo} alt="logo" width="32" />
          </Link>
        )} */}
        <span className={styles.trigger} onClick={this.toggle}>
          <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
        </span>
        <div className={styles.company}>{currentUser.name}</div>
        {(moment(currentUser.suspentionTime).diff(moment(), 'day') <= 30) &&
          <>

            <Alert
              message="Warning"
              description={`Expiring On-${moment(currentUser.suspentionTime).format(dateFormat)}`}
              type="warning"
              showIcon
              closable
            />
          </>}
        <RightContent {...this.props} />
      </div>
    )
  }
}
