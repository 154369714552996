export default (
    state = {
        registerLoader: false,
        verificationLoader: false,
        otpSendLoader: false,
        otpVerified: false,
        disableNumber: false,
        disableOtpSend: false
    },
    action
) => {
    switch (action.type) {
        case "REGISTER_SHOW_LOADER":
            return { ...state, registerLoader: true };
        case "REGISTER_HIDE_LOADER":
            return { ...state, registerLoader: false };
        case "VERIFICATION_SHOW_LOADER":
            return { ...state, verificationLoader: true };
        case "VERIFICATION_HIDE_LOADER":
            return { ...state, verificationLoader: false };
        case "OTP_SEND_SHOW_LOADER":
            return { ...state, otpSendLoader: true };
        case "OTP_SEND_HIDE_LOADER":
            return { ...state, otpSendLoader: false };
        case "SET_DISABLE_NUMBER":
            return { ...state, disableNumber: action.payload };
        case "SET_DISABLE_OTP_SEND":
            return { ...state, disableOtpSend: action.payload };
        case "SET_OTP_VERIFIED":
            return { ...state, otpVerified: action.payload };
        case "HIDE_OTP":
            return { ...state, showOtp: false };
        default:
            return state;
    }
};
