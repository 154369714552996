import React from 'react'
import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import { lazy } from 'react'
import {
  DashboardOutlined,
  DatabaseFilled,
  SettingOutlined,
  TeamOutlined,
  LineChartOutlined,
  AuditOutlined,
  BookFilled,
  DollarCircleOutlined,
  UserAddOutlined,
  FileFilled,
  FileTextOutlined,
} from '@ant-design/icons'
/*

const Dashboard = lazy(() => import('./containers/dashboard'))
const Undercons = lazy(() => import('./containers/undercons'))
const DefaultSettings = lazy(() => import('./containers/defaultSettings/setting'))
const AddFarmer = lazy(() => import('./containers/farmer/add'))
const AllFarmers = lazy(() => import('./containers/farmer/all'))
const FarmerSettings = lazy(() => import('./containers/farmer/setting/add'))
const AllFarmerSettings = lazy(() => import('./containers/farmer/setting/all'))
const AddMcc = lazy(() => import('./containers/mcp/add'))
const AllMcc = lazy(() => import('./containers/mcp/all'))
const AddMcpGroup = lazy(() => import('./containers/mcpGroup/add'))
const AllMcpGroup = lazy(() => import('./containers/mcpGroup/all'))
const AddFarmerCategory = lazy(() => import('./containers/farmerCategories/add'))
const AllFarmerCategory = lazy(() => import('./containers/farmerCategories/all'))
const Collections = lazy(() => import('./containers/collections'))
const Payments = lazy(() => import('./containers/payment'))
const Transactions = lazy(() => import('./containers/transactions'))
const AddTicket = lazy(() => import('./containers/tickets/add'))
const AllTicket = lazy(() => import('./containers/tickets/all'))
const TicketDetails = lazy(() => import('./containers/tickets/details'))
const AddRateChart = lazy(() => import('./containers/rateChart/add'))
const DateWiseMilkPurchaseSummary = lazy(() => import('./containers/reports/dateWiseMilkPurchaseSummary'))
const FarmerLedgerReport = lazy(() => import('./containers/reports/farmerLedgerReport'))
const McpSampleReport = lazy(() => import('./containers/reports/mcpSampleReport'))
const MilkBillReport = lazy(() => import('./containers/reports/milkBillReport'))
const MilkEditReport = lazy(() => import('./containers/reports/milkEditReport'))
const editRateChart = lazy(() => import('./containers/rateChart/edit/index'))
const AllRateCharts = lazy(() => import('./containers/rateChart/all/index'))
*/
//import Testing from './containers/aa/views/test'

import Dashboard from './elements/dashboard/views/live'

const NewDashboard = lazy(() => import('./elements/dashboardNew/views'))
// const Undercons = lazy(() => import('./containers/undercons'))
const DefaultSettings = lazy(() =>
  import('./containers/defaultSettings/setting/add')
)
// const AllSettings = lazy(() => import('./containers/defaultSettings/setting/all'))
const AddFarmer = lazy(() => import('./containers/farmer/add'))
const AllFarmers = lazy(() => import('./containers/farmer/all'))
const FarmerLedger = lazy(() =>
  import('./containers/farmer/unbilledCollections')
)
const FarmerSettings = lazy(() => import('./containers/farmer/setting/add'))
const AllFarmerSettings = lazy(() => import('./containers/farmer/setting/all'))
const BalanceScreen = lazy(() => import('./containers/farmer/balance/index'))
const AddMcc = lazy(() => import('./containers/mcp/add'))
const AllMcc = lazy(() => import('./containers/mcp/all'))
const IncentiveSettings = lazy(() =>
  import('./containers/mcp/incentiveSettings/addIncentive')
)
const IncentiveSettingsList = lazy(() =>
  import('./containers/mcp/incentiveSettings/all')
)
const CollectionQuantityIncentiveSettings = lazy(() =>
  import('./containers/mcp/incentiveSettings/collectionQuantityIncentive')
)
const BonusSettings = lazy(() =>
  import('./containers/mcp/incentiveSettings/bonusSettings')
)
const AddMcpGroup = lazy(() => import('./containers/mcpGroup/add'))
const AllMcpGroup = lazy(() => import('./containers/mcpGroup/all'))
const AddFarmerCategory = lazy(() =>
  import('./containers/farmerCategories/add')
)
const Collections = lazy(() => import('./containers/collections'))
const AllPayments = lazy(() => import('./containers/payment/all'))
const AddPayment = lazy(() => import('./containers/payment/add'))
const Transactions = lazy(() => import('./containers/transactions'))
const AddTicket = lazy(() => import('./containers/tickets/add'))
const AllTicket = lazy(() => import('./containers/tickets/all'))
const TicketDetails = lazy(() => import('./containers/tickets/details'))
const AddRateChart = lazy(() => import('./containers/rateChart/add'))
const AddStandardRateChart = lazy(() =>
  import('./containers/rateChart/standard')
)
const DateWiseMilkPurchaseSummary = lazy(() =>
  import('./containers/reports/dateWiseMilkPurchaseSummary')
)
const McpwiseMilkPurchaseSummary = lazy(() =>
  import('./containers/reports/mcpWiseMilkPurchaseSummary')
)
const FarmerLedgerReport = lazy(() =>
  import('./containers/reports/farmerLedgerReport')
)
const AddTemplate = lazy(() => import('./containers/smsTemplate/add'))
const AllTemplates = lazy(() => import('./containers/smsTemplate/all'))
const McpMilkBillReport = lazy(() =>
  import('./containers/reports/mcpMilkBillReport')
)
const MilkEditReport = lazy(() => import('./containers/reports/milkEditReport'))
const editRateChart = lazy(() => import('./containers/rateChart/edit/index'))
const AllRateCharts = lazy(() => import('./containers/rateChart/all/index'))
const MilkDispatch = lazy(() => import('./containers/milkDispatch/index'))
const AddBulkCollection = lazy(() => import('./containers/bulkCollection/add'))
const AddBulkMeasurement = lazy(() =>
  import('./containers/bulkCollection/scale')
)
const AllBulkMeasurement = lazy(() => import('./containers/bulkCollection/all'))
const Compare = lazy(() => import('./containers/bulkCollection/comparision'))
const AddManager = lazy(() => import('./containers/Manager/add'))
const AddRmrdDevice = lazy(() => import('./containers/rmrd/add'))
const AllRmrdDevices = lazy(() => import('./containers/rmrd/all'))
const AddRmrdAnalyzerParameters = lazy(() =>
  import('./containers/rmrd/analyzerParameters/add')
)
const AllRmrdAnalyzerParameters = lazy(() =>
  import('./containers/rmrd/analyzerParameters/all')
)
const AllRmrdCollections = lazy(() =>
  import('./containers/rmrd/collection/all')
)
const AllManager = lazy(() => import('./containers/Manager/all'))
const BillingSettings = lazy(() => import('./containers/bill/setting/settings'))
const AutomaticBillSetting = lazy(() =>
  import('./containers/bill/setting/automaticSetting')
)
const ManualBillSetting = lazy(() =>
  import('./containers/bill/setting/manualBilling')
)
const AllBills = lazy(() => import('./containers/bill/all'))
const McpReports = lazy(() => import('./containers/reports/mcpReports'))

const FarmerBillDetail = lazy(() => import('./containers/bill/details'))
const AllSms = lazy(() => import('./containers/sms/all'))
const AddBulkPurchase = lazy(() => import('./containers/bulkPurchase/add'))
const AllBulkPurchase = lazy(() => import('./containers/bulkPurchase/all'))
const AddProducts = lazy(() => import('./containers/products/add'))
const AllProducts = lazy(() => import('./containers/products/all'))
const AllSales = lazy(() => import('./containers/sales'))
const AllAnnouncement = lazy(() => import('./elements/announcement/views/all'))
const AddAnnouncement = lazy(() => import('./elements/announcement/views/add'))
const AllConflicts = lazy(() => import('./elements/conflicts/views/all'))
const SmsPurchase = lazy(() => import('./elements/smsPurchase/views/all'))
const CompanySettings = lazy(() => import('./elements/companySettings/views'))
const BluetoothSettings = lazy(() =>
  import('./elements/bluetoothSettings/view')
)
const ReportPrintSettings = lazy(() =>
  import('./elements/reportPrintSettings/view')
)
const AllReportPrintSettings = lazy(() =>
  import('./elements/reportPrintSettings/view/all')
)
const SyncLogs = lazy(() => import('./elements/SyncLogs/view'))
const EsperDetails = lazy(() =>
  import('./containers/mcp/all/enterpriseDetails')
)
const MachineLogs = lazy(() => import('./containers/machineLogs/all'))
const CleaningLogs = lazy(() =>
  import('./containers/machineLogs/all/cleaningLogs')
)
const FarmerSummary = lazy(() => import('./containers/reports/farmerSummary')) //my-code
const CurrentDashboard = lazy(() => import('./containers/dashboard/dashboard'))

//const Testing =import('./containers/aa/views/test')
const menu = [
  // {
  //   'path': '/Testing',
  //   'name': 'Testing',
  //   'icon': 'Testing',
  //   'title': 'Testing',
  //   'key': 'Testing',
  //   'component': Testing,
  //   'authority': [
  //     'company',
  //     'manager'
  //   ]
  // },

  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardOutlined />,
    title: 'Dashboard',
    key: 'dashboard',
    homepage: true,

    authority: ['company', 'manager'],
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        key: 'dashboard',
        title: 'Performance',
        component: Dashboard,
      },
      {
        path: '/dashboard/dashboardNew',
        name: 'Performance',
        key: 'performance',
        title: 'Performance',
        component: NewDashboard,
      },
      {
        path: '/dashboard/quotas',
        name: 'Quotas/Limit',
        key: 'quotas',
        title: 'Quotas/Limit',
        component: CompanySettings,
      },
      {
        path: '/dashboard/currentDashboard',
        name: 'Current Dashboard',
        key: 'currentDashboard',
        title: 'Current Dashboard',
        component: CurrentDashboard,
      },
    ],
  },

  {
    path: '/settings',
    name: 'Settings',
    icon: <SettingOutlined />,
    key: 'settings',
    authority: ['company'],
    children: [
      {
        path: '/settings/add',
        name: 'Default Settings',
        key: 'defaultSettings',
        title: 'Default Settings',
        component: DefaultSettings,
      },
      {
        path: '/settings/incentiveSettings',
        name: 'Quality Incentive Settings',
        title: 'Add Incentive Settings',
        key: 'addIncentiveSettings',
        dontShowOnMenu: true,
        component: IncentiveSettings,
      },
      {
        path: '/settings/incentiveSettingsList',
        name: 'Quality Incentive',
        title: 'All Incentive Settings',
        key: 'incentiveSettings',
        component: IncentiveSettingsList,
      },
      {
        path: '/settings/collectionQuantityIncentiveSettings',
        name: 'Quantity Incentive',
        title: 'All Incentive Settings',
        key: 'CollectionQuantityIncentiveSettings',
        component: CollectionQuantityIncentiveSettings,
      },
      /* {
           'path': '/mcp/bonusSettings',
           'name': 'Bonus Settings',
           'title': 'Bonus Settings',
           'key': 'BonusSettings',
           'component': BonusSettings
       },*/
      /* {
           'path': '/billing/setting',
           'name': 'Billing Settings',
           'title': 'Billing Settings',
           'key': 'BillingSettings',
           'component': BillingSettings
       },*/

      {
        path: '/settings/print/settings',
        name: 'Report Print Settings',
        key: 'reportPrintSettings',
        dontShowOnMenu: true,
        component: ReportPrintSettings,
        authority: ['company'],
      },
      {
        path: '/settings/printsettings/all',
        name: 'All Report Print Settings',
        // 'icon': <SettingOutlined/>,
        key: 'allReportPrintSettings',
        component: AllReportPrintSettings,
        authority: ['company'],
      },
      {
        path: '/settings/reportPrintSettings/edit/:id',
        name: 'Update Report Print Settings',
        title: 'Update Report Print Settings',
        key: 'editReportPrintSettings',
        dontShowOnMenu: true,
        component: ReportPrintSettings,
      },

      // {
      //   'path': '/defaultSetting/all',
      //   'name': 'All Settings',
      //   'key': 'all',
      //   'title': 'All Settings',
      //   'component': AllSettings
      // },
    ],
  },

  {
    path: '/Mcp',
    name: 'Mcp',
    key: 'mcp',
    icon: <UserAddOutlined />,
    authority: ['company'],
    children: [
      {
        path: '/mcp/add',
        name: 'Add MCP',
        key: 'addMcp',

        title: 'Add MCP',

        component: AddMcc,
      },
      {
        path: '/mcp/all',
        name: 'All MCP',
        title: 'All MCP',
        component: AllMcc,
      },
      {
        path: '/mcp/editMcc/:id',
        name: 'Update MCP',
        title: 'Update MCP',
        key: 'editMcc',
        dontShowOnMenu: true,
        component: AddMcc,
      },
      {
        path: '/mcp/group/add',
        name: 'Add MCP Group',
        key: 'addMcpGroup',
        title: 'Add MCP Group',
        dontShowOnMenu: true,
        component: AddMcpGroup,
      },
      {
        path: '/mcp/group/all',
        name: 'All MCP Group',
        title: 'All MCP Group',
        component: AllMcpGroup,
      },
      {
        path: '/mcp/editMcpGroup/:id',
        name: 'Update MCP Group',
        title: 'Update MCP Group',
        key: 'editMcpGroup',
        dontShowOnMenu: true,
        component: AddMcpGroup,
      },
      {
        path: '/mcp/manager/add',
        name: 'Add User',
        key: 'addManager',
        dontShowOnMenu: true,
        component: AddManager,
        authority: ['company'],
      },
      {
        path: '/mcp/manager/edit/:id',
        name: 'Edit User',
        key: 'editManager',
        dontShowOnMenu: true,
        component: AddManager,
        authority: ['company', 'manager'],
      },
      {
        path: '/mcp/manager/all',
        name: 'All User',
        key: 'allManager',
        component: AllManager,
        authority: ['company'],
      },
      /* {
           'path': '/rmrdDevice/add',
           'name': 'Add RMRD Device',
           'key': 'addRmrdDevice',
           dontShowOnMenu: true,
           'component': AddRmrdDevice,
           'authority': [
               'company'
           ]
       },*/
      /* {
           'path': '/rmrdDevice/all',
           'name': 'All RMRD Devices',
           'key': 'allRmrdDevice',
           'component': AllRmrdDevices,
           'authority': [
               'company'
           ]
       }*/
    ],
  },
  /*  {
        'path': '/mcp',
        'name': 'MCP',
        'key': 'mcp',
        'icon': <ShopFilled/>,
        'authority': [
            'company',
            'manager'
        ],
        'children': [
            {
                'path': '/mcp/add',
                'name': 'Add MCP',
                'key': 'addMcp',
                'title': 'Add MCP',
                'component': AddMcc
            },
            {
                'path': '/mcp/all',
                'name': 'All MCP',
                'title': 'All MCP',
                'component': AllMcc
            },
            {
                'path': '/mcp/editMcc/:id',
                'name': 'Update MCP',
                'title': 'Update MCP',
                'key': 'editMcc',
                dontShowOnMenu: true,
                'component': AddMcc
            }
        ]
    },*/
  /*  {
        'path': '/additionalSetting',
        'name': 'Incentive/Bonus Settings',
        'key': 'additionalSetting',
        'icon': <SettingOutlined/>,
        'authority': [
            'company',
            'manager'
        ],
        'children': [
            {
                'path': '/mcp/incentiveSettings',
                'name': 'Quality Incentive Settings',
                'title': 'Add Incentive Settings',
                'key': 'incentiveSettings',
                'component': IncentiveSettings
            },
            {
                'path': '/mcp/incentiveSettingsList',
                'name': 'Quality Incentive List',
                'title': 'All Incentive Settings',
                'key': 'incentiveSettings',
                'component': IncentiveSettingsList

            },
            {
                'path': '/mcp/collectionQuantityIncentiveSettings',
                'name': 'Quantity Incentive Settings',
                'title': 'All Incentive Settings',
                'key': 'CollectionQuantityIncentiveSettings',
                'component': CollectionQuantityIncentiveSettings,
                'authority': [
                    'company',
                ],
            },
            // {
            //   'path': '/mcp/bonusSettings',
            //   'name': 'Bonus Settings',
            //   'title': 'Bonus Settings',
            //   'key': 'BonusSettings',
            //   'component': BonusSettings
            // },
            {
                'path': '/mcp/incentiveSettings/:id',
                'name': 'Edit Incentive Settings',
                'title': 'Edit Incentive Settings',
                'key': 'editIncentiveSettings',
                dontShowOnMenu: true,
                'component': IncentiveSettings
            }
        ]
    },*/
  {
    path: '/farmer',
    name: 'Farmers',
    key: 'farmer',
    icon: <TeamOutlined />,

    authority: ['company', 'manager'],
    children: [
      /* {
        'path': '/farmer/farmerCategory/add',
        'name': 'Add Farmer Category',
        'key': 'addFarmerCategory',
        'title': 'Add Farmer Category',
        'component': AddFarmerCategory
      },
      {
        'path': '/farmer/farmerCategory/all',
        'name': 'All Farmer Category',
        'title': 'All Farmer Category',
        'component': AllFarmerCategory
      }, */
      {
        path: '/farmer/editFarmerCategory/:id',
        name: 'Update Farmer Category',
        title: 'Update Farmer Category',
        key: 'editFarmerCategory',
        dontShowOnMenu: true,
        component: AddFarmerCategory,
      },
      {
        path: '/farmer/settings/add',
        name: 'Add Farmer Settings',
        title: 'Farmer Settings',
        key: 'farmerSettings',
        dontShowOnMenu: true,
        component: FarmerSettings,
      },

      {
        path: '/farmer/settings/edit/:id',
        name: 'Farmer Settings',
        title: 'Farmer Settings',
        key: 'farmerSettingsEdit',
        dontShowOnMenu: true,
        component: FarmerSettings,
      },
      /* {
           'path': '/farmer/settings/all',
           'name': 'All Farmer Settings',
           'title': 'All Farmer Settings',
           'key': 'allFarmerSettings',
           // dontShowOnMenu: true,
           'component': AllFarmerSettings
       },*/
      {
        path: '/farmer/add',
        name: 'Add Farmer',
        title: 'Add Farmer',
        key: 'addFarmer',

        component: AddFarmer,
      },
      {
        path: '/farmer/all',
        name: 'All Farmers',
        title: 'All Farmers',
        component: AllFarmers,
      },
      {
        path: '/farmer/balance/all',
        name: 'Balance Screen',
        title: 'Balance screen',
        key: 'farmerBalanceScreen',
        component: BalanceScreen,
        // 'authority': [
        //     'company',
        //     'admin'
        // ],
      },

      {
        path: '/farmer/settings/all',
        name: 'Farmer Extra Fields',
        title: 'Farmer Extra Fields',
        key: 'allFarmerSettings',
        component: AllFarmerSettings,
      },

      {
        path: '/farmer/billing/automatic',
        name: 'Billing Automatic',
        key: 'automaticBillingSetting',
        component: AutomaticBillSetting,
        authority: ['company', 'admin'],
      },
      {
        path: '/farmer/billing/manual',
        name: 'Billing Manual',
        key: 'manualBillingSetting',
        component: ManualBillSetting,
        authority: ['company', 'admin'],
      },
      {
        path: '/farmer/billing/Detail',
        name: 'Farmer Bill Details',
        key: 'billDetail',
        dontShowOnMenu: true,
        component: FarmerBillDetail,
        authority: ['company'],
      },
      {
        path: '/farmer/edit/:id',
        name: 'Update Farmer',
        title: 'Update Farmer',
        key: 'editFarmer',
        dontShowOnMenu: true,
        component: AddFarmer,
      },
      {
        path: '/farmer/collections/:farmerUUID/:mcpId',
        name: 'Farmer Ledger',
        title: 'Farmer Ledger',
        key: 'unbilledcollectionsFarmer',
        dontShowOnMenu: true,
        component: FarmerLedger,
      },
      {
        path: '/farmer/merge/:mergeId',
        name: 'Merge Farmer',
        title: 'Merge Farmer',
        key: 'mergeFarmer',
        dontShowOnMenu: true,
        component: AddFarmer,
      },
      {
        path: '/farmer/transactions',
        name: 'Farmer Report',
        key: 'transactions2',
        component: Transactions,
        authority: ['company', 'manager'],
      },
      {
        path: '/farmer/payments/all',
        name: 'Payment Report',
        key: 'allPayments',
        component: AllPayments,
        authority: ['company', 'manager'],
      },
      {
        path: '/farmer/payments/add',
        name: 'Add Payment',
        icon: <DollarCircleOutlined />,
        key: 'addPayments',
        component: AddPayment,
        dontShowOnMenu: true,
        authority: ['company', 'manager'],
      },
      {
        path: '/farmer/payments/edit/:id',
        name: 'Edit Payment',
        key: 'editPayments',
        component: AddPayment,
        dontShowOnMenu: true,
        authority: ['company', 'manager'],
      },
      {
        path: '/farmer/sales',
        name: 'Sales Report',
        key: 'sales',
        component: AllSales,
      },
      {
        path: '/farmer/Summary',
        name: 'Farmer Summary',
        key: 'farmerSummary',
        component: FarmerSummary,
        authority: ['company', 'manager'],
      },
    ],
  },
  {
    path: '/collections',
    name: 'Collections',
    icon: <DatabaseFilled />,
    key: 'collections',
    component: Collections,
    authority: ['company', 'manager'],
  },
  {
    path: '/rateChart',
    name: 'Rate',
    icon: <LineChartOutlined />,
    key: 'rateChart',
    authority: ['company'],
    children: [
      {
        path: '/rateChart/add',
        name: 'Add Rate Chart',
        key: 'addRateChart',
        component: AddRateChart,
        authority: ['company', 'manager'],
      },

      {
        path: '/rateChart/list',
        name: 'All Rate Charts',
        key: 'allRateCharts',
        component: AllRateCharts,
        authority: ['company', 'manager'],
      },
      {
        path: '/rateCharts/edit/:id',
        name: 'Rate Charts Edit',
        title: 'Rate Charts Edit',
        key: 'edit',
        component: editRateChart,
        dontShowOnMenu: true,
      },
    ],
  },

  {
    path: '/ticket',
    name: 'Tickets',
    key: 'ticket',
    icon: <AuditOutlined />,
    dontShowOnMenu: true,
    authority: ['company'],
    children: [
      {
        path: '/ticket/add',
        name: 'Add Ticket',
        title: 'Add Ticket',
        component: AddTicket,
      },
      {
        path: '/ticket/all',
        name: 'All Ticket',
        title: 'All Ticket',
        component: AllTicket,
      },
      {
        path: '/ticketDetails/:id',
        name: 'Ticket',
        icon: 'dashboard',
        key: 'ticketDetails',
        dontShowOnMenu: true,
        component: TicketDetails,
      },
    ],
  },

  {
    path: '/report',
    name: 'Reports',
    key: 'reports',
    icon: <UserAddOutlined />,
    authority: ['company', 'manager'],
    children: [
      /*{
          'path': '/report/dateWiseMilkPurchaseSummary',
          'name': 'Date Wise Milk Purchase',
          'title': 'Date Wise Milk Purchase',
          'key': 'dateWiseMilkPurchase',

          'component': DateWiseMilkPurchaseSummary
      },
      {
          'path': '/report/mcpWiseMilkPurchaseSummary',
          'name': 'MCP Wise Milk Purchase',
          'title': 'MCP Wise Milk Purchase',
          'key': 'mcpWiseMilkPurchase',
          'component': McpwiseMilkPurchaseSummary
      },

      {
          'path': '/report/milkBillReport',
          'name': 'Milk Purchase Report',
          'title': 'Milk Purchase Report',
          'key': 'milkBillReport',
          'component': McpMilkBillReport
      },
      {
          'path': '/report/farmerLedgerReport',
          'name': 'Farmer Ledger Report',
          'title': 'Farmer Ledger Report',
          'key': 'farmerLedgerReport',
          'component': FarmerLedgerReport
      },*/
      // {
      //     'path': '/report/collections',
      //     'name': 'Collection Report',
      //     'key': 'collections2',
      //     'component': Collections,
      //     'authority': [
      //         'company',
      //         'manager'
      //     ]
      // },
      {
        path: '/report/transactions',
        name: 'Farmer Report',
        key: 'transactions',
        component: Transactions,
        authority: ['company', 'manager'],
      },
      {
        path: '/report/bills',
        name: 'Bill Report',
        key: 'allBills',
        component: AllBills,
        authority: ['company', 'manager'],
      },
      {
        path: '/report/mcpReports',
        name: 'MCP Report',
        key: 'mcpReports',
        component: McpReports,
        authority: ['company', 'manager'],
      },
      {
        path: '/report/milkEditReport',
        name: 'Milk Edit Report',
        title: 'Milk Edit Report',
        key: 'milkEditReport',
        component: MilkEditReport,
      },
      {
        path: '/report/cleaningReport',
        name: 'Cleaning Report',
        title: 'Cleaning Report',
        key: 'cleanLogs',
        component: CleaningLogs,
      },
      {
        path: '/report/smsSentReport',
        name: 'SMS Sent Report',
        title: 'SMS Sent Report',
        key: 'smsSentReport',
        component: AllSms,
      },
      {
        path: '/report/smsPurchaseHistory',
        name: 'SMS Purchase Report',
        title: 'SMS Purchase Report',
        key: 'smsPurchaseReport',
        component: SmsPurchase,
        authority: ['company', 'admin'],
      },
      {
        path: '/report/bluetooth',
        name: 'Mcp Bluetooth Status',
        title: 'Mcp Bluetooth Status',
        key: 'bluetooth',
        component: BluetoothSettings,
      },
      {
        path: '/report/machineLogs',
        name: 'Machine Logs',
        title: 'Machine Logs',
        key: 'machineLogs',
        component: MachineLogs,
      },
      /* {
           'path': '/sales',
           'name': 'Sales Report',
           'key': 'sales',
           'component': AllSales
       },*/
    ],
  },
  // {
  //     'path': '/rmrd',
  //     'name': 'RMRD',
  //     'key': 'rmrdSolution',
  //     'icon': <UserAddOutlined/>,
  //     'authority': [
  //         'company'
  //     ],
  //     'children': [
  //         {
  //             'path': '/rmrd/add',
  //             'name': 'Add RMRD Device',
  //             'key': 'addRmrdDevice',
  //             'component': AddRmrdDevice,
  //             'authority': [
  //                 'company'
  //             ]
  //         },
  //         {
  //             'path': '/rmrd/all',
  //             'name': 'All RMRD Devices',
  //             'key': 'allRmrdDevice',
  //             'component': AllRmrdDevices,
  //             'authority': [
  //                 'company'
  //             ]
  //         },
  //         {
  //             'path': '/rmrd/rmrdAnalyzerParameters/add',
  //             'name': 'Add RMRD Analyzer Parameters',
  //             'key': 'addRmrdAnalyzerParameters',
  //             'component': AddRmrdAnalyzerParameters,
  //             'authority': [
  //                 'company'
  //             ]
  //         },

  //         {
  //             'path': '/rmrd/AnalyzerParameters/edit/:id',
  //             'name': 'Edit Analyzer Parameters',
  //             'key': 'editRmrdAnalyzerParameters',
  //             'component': AddRmrdAnalyzerParameters,
  //             dontShowOnMenu:true,
  //             'authority': [
  //                 'company'
  //             ]
  //         },
  //         {
  //             'path': '/rmrd/AnalyzerParameters/all',
  //             'name': 'All Analyzer Parameters',
  //             'key': 'allRmrdAnalyzerParameters',
  //             'component': AllRmrdAnalyzerParameters,
  //             'authority': [
  //                 'company'
  //             ]
  //         },
  //         {
  //             'path': '/rmrd/rmrdCollections/all',
  //             'name': 'All RMRD Collections',
  //             'key': 'allRmrdCollections',
  //             'component': AllRmrdCollections,
  //             'authority': [
  //                 'company'
  //             ]
  //         }
  //     ]
  // },

  // {
  //   'path': '/sales',
  //   'name': 'Sales',
  //   'icon': 'book',
  //   'key': 'sales',
  //   'component': AllSales
  // },
  {
    path: '/product',
    name: 'Product',
    key: 'product',
    icon: <BookFilled />,
    children: [
      {
        path: '/product/add',
        name: 'Add Product',
        key: 'addProduct',
        component: AddProducts,
        authority: ['company', 'manager'],
      },
      {
        path: '/product/edit/:id',
        name: 'Edit Product',
        key: 'editProduct',
        component: AddProducts,
        dontShowOnMenu: true,
        authority: ['company', 'manager'],
      },
      {
        path: '/product/all',
        name: 'All Products',
        key: 'allProducts',
        component: AllProducts,
        authority: ['company', 'manager'],
      },
    ],
    authority: ['company', 'manager'],
  },
  /* {
       'path': '/payments',
       'name': 'Payments',
       'icon': <DollarCircleOutlined/>,
       'key': 'payments',
       'children': [
           {
               'path': '/payments/add',
               'name': 'Add Payment',
               'icon': <DollarCircleOutlined/>,
               'key': 'addPayments',
               'component': AddPayment,
               'authority': [
                   'company',
                   'manager'
               ]
           },
           {
               'path': '/payments/all',
               'name': 'All Payments',
               'icon': <DollarCircleOutlined/>,
               'key': 'allPayments',
               'component': AllPayments,
               'authority': [
                   'company',
                   'manager'
               ]
           }

       ],
       'authority': [
           'company',
           'manager'
       ]
   },*/

  // {
  //   'path': '/milkDispatch',
  //   'name': 'Milk Dispatch',
  //   'icon': 'database',
  //   'key': 'milkDispatch',
  //   'component': MilkDispatch,
  //   'authority': [
  //     'company',
  //     'manager'
  //   ]
  // },
  {
    path: '/rmrd-report',
    name: 'RMRD Report',
    icon: <LineChartOutlined />,
    key: 'rmrd',
    dontShowOnMenu: true,
    authority: ['company'],
    children: [
      {
        path: '/rmrd/addMilkReception',
        name: 'Add Milk Reception',
        key: 'addMilkReception',
        component: AddBulkCollection,
        authority: ['company'],
      },
      {
        path: '/editMilkReception/:id',
        name: 'Edit Milk Reception',
        key: 'editMilkReception',
        component: AddBulkCollection,
        dontShowOnMenu: true,
        authority: ['company'],
      },
      // {
      //   'path': '/allMilkReception',
      //   'name': 'All Milk Reception',
      //   'key': 'allMilkReception',
      //   'component': AllBulkMeasurement,
      //   'authority': [
      //     'company'
      //   ]
      // }
    ],
  },
  {
    path: '/template',
    name: 'Template',
    icon: <FileFilled />,
    key: 'template',
    authority: ['company'],
    children: [
      {
        path: '/template/add',
        name: 'Add Template',
        // 'icon': <PaperClipOutlined/>,
        key: 'addTemplate',
        component: AddTemplate,
        authority: ['company'],
      },
      {
        path: '/template/edit/:id',
        name: 'Edit Template',
        key: 'editTemplate',
        component: AddTemplate,
        dontShowOnMenu: true,
        authority: ['company'],
      },
      {
        path: '/template/all',
        name: 'All Templates',
        key: 'allTemplates',
        component: AllTemplates,
        authority: ['company'],
      },
    ],
  },

  /*{
      'path': '/billing',
      'name': 'Billing',
      'icon': <FileTextOutlined/>,
      'key': 'bill',
      'authority': [
          'company'
      ],
      'children': [
          {
              'path': '/billing/automatic',
              'name': 'Automatic Billing',
              'key': 'automaticBillingSetting',
              'component': AutomaticBillSetting,
              'authority': [
                  'company'
              ]
          },
          {
              'path': '/billing/manual',
              'name': 'Manual Billing ',
              'key': 'manualBilling',
              'component': ManualBillSetting,
              'authority': [
                  'company'
              ]
          },

          {
              'path': '/billing/list/Detail',
              'name': 'Farmer Bill Details',
              'key': 'farmerBillDetail',
              dontShowOnMenu: true,
              'component': FarmerBillDetail,
              'authority': [
                  'company'
              ]
          }
      ]
  },*/
  {
    path: '/bulkMilkPurchase',
    name: 'Bulk Milk Purchase',
    icon: <FileTextOutlined />,
    key: 'bulkPurchase',
    dontShowOnMenu: true,
    // 'component': AddBulkPurchase,
    authority: ['company', 'manager'],
    children: [
      {
        path: '/bulkMilkPurchase/add',
        name: 'Add Bulk Purchase',
        key: 'addBulkMilkPurchase',
        component: AddBulkPurchase,
        authority: ['company'],
      },
      // {
      //   'path': '/bulkMilkPurchase/all',
      //   'name': 'All Bulk Milk Purchase',
      //   'key': 'allBulkPurchase',
      //   'component': AllBulkPurchase,
      //   'authority': [
      //     'company'
      //   ]
      // },
      {
        path: '/bulkMilkPurchase/edit/:id',
        name: 'Edit Bulk Purchase',
        key: 'editBulkPurchase',
        dontShowOnMenu: true,
        component: AddBulkPurchase,
        authority: ['company'],
      },
    ],
  },
  {
    path: '/announcement',
    name: 'Announcement',
    icon: <FileTextOutlined />,
    key: 'announcement',
    dontShowOnMenu: true,
    // 'component': AddBulkPurchase,
    authority: ['company', 'manager'],
    children: [
      {
        path: '/announcement/add',
        name: 'Add Announcement',
        key: 'addAnnouncemnet',
        component: AddAnnouncement,
        authority: ['company'],
      },
      //  {
      //   'path': '/announcement/all',
      //   'name': 'All announcement',
      //   'key': 'allAnnouncement',
      //   'component': AllAnnouncement,
      //   'authority': [
      //     'company'
      //   ]
      // }
    ],
  },
  {
    path: '/conflicts',
    name: 'Conflicts',
    icon: 'message',
    key: 'conflicts',
    children: [
      {
        path: '/conflicts/all',
        name: 'All Conflicts',
        key: 'allConflicts',
        component: AllConflicts,
        authority: ['company'],
      },
    ],
  },
  // {
  //   'path': '/smsPurchase',
  //   'name': 'SMS Purchase',
  //   'icon': 'dollar',
  //   'key': 'smsPurchase',
  //   'component': SmsPurchase
  // },
  {
    path: '/miscellaneous',
    name: 'Miscellaneous',
    icon: <DashboardOutlined />,
    key: 'miscellaneous',
    children: [
      /*  {
            'path': '/conflicts/all',
            'name': 'All Conflicts',
            'key': 'allConflicts',
            'component': AllConflicts,
            'authority': [
                'company'
            ]
        },*/
      {
        path: '/miscellaneous/announcement/all',
        name: 'Announcement',
        key: 'allAnnouncement',
        component: AllAnnouncement,
        authority: ['company'],
      },
      {
        path: '/miscellaneous/bulkMilkPurchase/all',
        name: 'Bulk Milk Purchase',
        key: 'allBulkPurchase',
        component: AllBulkPurchase,
        authority: ['company'],
      },
      {
        path: '/miscellaneous/allMilkReception',
        name: 'Milk Reception',
        key: 'allMilkReception',
        component: AllBulkMeasurement,
        authority: ['company'],
      },
      {
        path: '/miscellaneous/milkDispatch',
        name: 'Milk Dispatch',
        // 'icon': <DatabaseFilled/>,
        key: 'milkDispatch',
        component: MilkDispatch,
        authority: ['company', 'manager'],
      },
    ],
  },

  {
    path: '/sync/logs',
    name: 'Sync Logs',
    icon: <SettingOutlined />,
    key: 'syncLogs',
    component: SyncLogs,
    authority: ['company'],
  },
  {
    path: '/enterpriseDetails/:id',
    name: 'Enterprise Details',
    title: 'Enterprise Details',
    icon: <SettingOutlined />,
    key: 'enterpriseDetails',
    component: EsperDetails,
    authority: ['company'],
  },
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, (p) => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {
  let keyArr = keyString.split('.')

  let val = _.find(menu, (p) => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu
