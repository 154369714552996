export default (
    state = {
        tableLoader: false,
        bestQuantityMcps:[],
        worstQuantityMcps:[],
        bestFatMcps:[],
        worstFatMcps:[],
        bestSnfMcps:[],
        worstSnfMcps:[],
        bestAddedWaterMcps:[],
        worstAddedWaterMcps:[],
        quantityLoader: false,
        fatLoader: false,
        snfloader: false,
        addWaterLoader: false,
    },
    action
) => {
    switch (action.type) {
        case "set_quantity_loader":
            return { ...state, quantityLoader: action.payload };
        case "set_fat_loader":
            return { ...state, fatLoader: action.payload };
        case "set_snf_loader":
            return { ...state, snfLoader: action.payload };
        case "set_addedWater_loader":
            return { ...state, addedWaterLoader: action.payload };
        case "set_quantity_mcps":
            return {...state, bestQuantityMcps: action.payload.bestMcps, worstQuantityMcps: action.payload.worstMcps}
        case "set_fat_mcps":
            return {...state, bestFatMcps: action.payload.bestMcps, worstFatMcps: action.payload.worstMcps}
        case "set_snf_mcps":
            return {...state, bestSnfMcps: action.payload.bestMcps, worstSnfMcps: action.payload.worstMcps}
        case "set_addedWater_mcps":
            return {...state, bestAddedWaterMcps: action.payload.bestMcps, worstAddedWaterMcps: action.payload.worstMcps}
        default :
        return state

    }
}