export default (
    state = {
        loginLoader: false,
        verificationLoader: false,
        otpSendLoader: false,
        loginResponse: {},
        showOtp: false,
        otpResponse: {}
    },
    action
) => {
    switch (action.type) {
        case "LOGIN_SHOW_LOADER":
            return { ...state, loginLoader: true };
        case "LOGIN_HIDE_LOADER":
            return { ...state, loginLoader: false };
        case "VERIFICATION_SHOW_LOADER":
            return { ...state, verificationLoader: true };
        case "VERIFICATION_HIDE_LOADER":
            return { ...state, verificationLoader: false };
        case "OTP_SEND_SHOW_LOADER":
            return { ...state, otpSendLoader: true };
        case "OTP_SEND_HIDE_LOADER":
            return { ...state, otpSendLoader: false };
        case "SET_LOGIN_RESPONSE":
            return { ...state, loginResponse: action.payload };
        case "SHOW_OTP":
            return { ...state, showOtp: true };
        case "HIDE_OTP":
            return { ...state, showOtp: false };
        default:
            return state;
    }
};
