import React, { useState, useEffect, useRef } from 'react'

import styles from './login.less'
import {
    Form, Card, Input, Button
} from 'antd'
import { UserOutlined, PhoneOutlined } from '@ant-design/icons'
import { push } from 'connected-react-router'
import { useSelector, useDispatch } from 'react-redux'
import { getLatestSettings } from '../../../utils'


const DeleteAccountForm = (props) => {
    const dispatch = useDispatch();
    const [sumbit, setSumbit] = useState(false)
    const [state, setState] = useState({
        anchorFlag: true,
        loader: false,
        otp: undefined,
        cardLoading: true,
        modal: false
    })
    const [loading, setLoading] = useState(false)
    const captchaDemoRef = useRef(null)
    let captchaDemo = captchaDemoRef.current

    useEffect(() => {
        dispatch({ type: 'HIDE_OTP' })
        dispatch({
            type: 'SET_LOGIN_RESPONSE',
            payload: {}
        })
        dispatch({ type: 'OTP_SEND_HIDE_LOADER' })
        dispatch({ type: 'LOGIN_HIDE_LOADER' })
        captchaDemo = captchaDemoRef.current
        if (captchaDemo) {
            captchaDemo.reset()
            captchaDemo.execute()
        }
        setState({ ...state, cardLoading: false })
    }, [captchaDemoRef])

    const onFinish = (e) => {
        return (async (err, values) => {
            if (!err) {

                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                    setSumbit(true)
                }, 2500)

            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()

        props.form.validateFields(onFinish())

    }

    const anchorDisable = (e) => {
        setState({
            ...state,
            anchorFlag: false
        })
    }


    const { getFieldDecorator } = props.form
    const { flag, anchorFlag, cardLoading, otp, modal } = state

    return (
      <Card loading={cardLoading} bordered={false} bodyStyle={{ display: 'flex' }}>
          {!sumbit ?
            <div className={styles.form} style={{ width: '100%', marginTop: -20 }}>
                <h2>Fill details to delete your account?</h2>
                <Form onSubmit={handleSubmit}>

                    <Form.Item className={styles.deleteAccountForm}>
                        {getFieldDecorator('companyName', {
                            rules: [{ required: true, message: 'Please enter your company name !' }]
                        })(
                          <Input prefix={
                              <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>
                          }
                                 onChange={() => {
                                   anchorDisable()
                               }} placeholder="Company Name"/>
                      )}
                  </Form.Item>
                  <Form.Item className={styles.deleteAccountForm}>
                      {getFieldDecorator('email', {
                          rules: [{ required: true, message: 'Please enter your email address!' }]
                      })(
                        <Input prefix={
                            <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>
                        }
                               onChange={() => {
                                   anchorDisable()
                               }} placeholder="Email address"/>
                      )}
                  </Form.Item>
                  <Form.Item className={styles.deleteAccountForm}>
                      {getFieldDecorator('mobile', {
                          rules: [{ required: true, message: 'Please input your mobile number!' }]
                      })(
                        <Input prefix={
                            <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>
                        } type="text"
                               placeholder="Phone Number"/>
                      )}
                  </Form.Item>
                  <Form.Item className={styles.deleteAccountForm}>
                      {getFieldDecorator('mspID', {
                          rules: [{ required: true, message: 'Please input your MPC ID!' }]
                      })(
                        <Input prefix={
                            <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>
                        } type="text"
                               placeholder="MCP ID"/>
                      )}
                  </Form.Item>


                  <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
                      Delete My Account.
                  </Button>


              </Form>
          </div>
          :
          <div className={styles.form} style={{ width: '100%', color: 'green', fontSize: '24px', fontWeight: 500, textAlign:'center' }}>
            Your request to delete your account is saved with us, We will notify you shortly
          </div>
          }


      </Card>
    )
}

const WrappedDeleteForm = Form.create()(DeleteAccountForm)

export default WrappedDeleteForm
