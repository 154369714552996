export default (state = {
    List: {},
    serverLogsList: {},
    totalServerLogs:0,
    totalLogs:0
}, action) => {
    switch (action.type) {
        case 'SYNC_LOGS_SHOW_LOADER':
            return { ...state, loader: true };

        case 'SERVER_LOGS_SHOW_LOADER':
            return { ...state, serverLoader: true };

        case 'SYNC_LOGS_HIDE_LOADER':
            return { ...state, loader: false };

        case 'SERVER_LOGS_HIDE_LOADER':
            return { ...state, serverLoader: false };

        case 'SYNC_LOGS':
            return { ...state, List: action.payload };

        case 'SERVER_LOGS':
            return { ...state, serverLogsList: action.payload };

        case 'TOTAL_SYNC_LOGS':
            return { ...state, totalLogs: action.payload };

        case 'TOTAL_SERVER_LOGS':
            return { ...state, totalServerLogs: action.payload };

        default:
            return state;

    }
};
