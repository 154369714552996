import { apiUrl } from "../../../settings";

export const registerUrl = () => {
    return apiUrl + '/register';
};
export const sendOtpUrl = (mobileNumber) => {
    return apiUrl + `/sendOtp?mobileNumber=${mobileNumber}`;
};

export const verifyOtpUrl = () => {
    return apiUrl + `/verifyOtp`;
};