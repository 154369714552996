import io from 'socket.io-client';
import { socketUrl } from './settings';
let socket;

export const connectSocket = (id) => {

    socket = io(socketUrl, { secure: true });
    socket.on('connect', () => {
        console.log("on conenct")        
        socket.emit('companyAuthenticate', id);
    });
}

export const subscribeToChat = (cb) => {
   
    if (!socket) return (true);
    socket.on('SYNC_COLLECTION_DASHBOARD', msg => {
        return cb(null,msg);
    });
}



// export const authenticateCompany = () => {

// }

// export const disconnectSocket = () => {
//     console.log('Disconnecting socket...');
//     if (socket) socket.disconnect();
// }
// export const subscribeToChat = (cb) => {
//     if (!socket) return (true);
//     socket.on('chat', msg => {
//         console.log('Websocket event received!');
//         return cb(null, msg);
//     });
// }
// export const sendMessage = (room, message) => {
//     if (socket) socket.emit('chat', { message, room });
// }