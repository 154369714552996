import axios from "axios";
import { registerUrl, verifyOtpUrl, sendOtpUrl } from "../api/registerUrl";
import { notification, message } from 'antd'
import { useSelector } from "react-redux";
import { getToken } from "../../../request";
import { push } from "connected-react-router";
import { Auth } from "aws-amplify";

export const register = (inputData, recaptchaToken) => async (dispatch) => {
    dispatch({ type: 'REGISTER_SHOW_LOADER' });
    await axios
        .post(registerUrl(), inputData, { Headers: { captcha: recaptchaToken } })
        .then(function ({ data }) {
            dispatch({ type: 'REGISTER_HIDE_LOADER' });

            if (!data.error) {
                if (data.user.userType !== 'company' && data.user.userType !== 'manager') {

                    return notification.error({
                        message: 'Invalid Login',
                        description: data.message
                    })
                }
                else {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('user', JSON.stringify(data.user))
                    dispatch({
                        type: 'SET_CURRENT_USER',
                        user: data.user
                    })

                    setTimeout(() => {
                        dispatch(push('/'))
                    }, 300)
                }
            } else {

                notification.error({
                    message: 'Signing up failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'REGISTER_HIDE_LOADER' });
        });
};
export const sendOtpAction = (inputData) => async (dispatch) => {
    dispatch({ type: 'OTP_SEND_SHOW_LOADER' });
    await axios
        .get(sendOtpUrl(inputData), { Headers: { ...getToken().headers } })
        .then(function ({ data }) {
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
            dispatch({ type: 'OTP_SEND_HIDE_LOADER' });

            if (data.success) {
                dispatch({ type: 'SET_DISABLE_NUMBER', payload: true })
                dispatch({ type: 'SET_DISABLE_OTP_SEND', payload: true })
            }
            else {
                notification.error({
                    message: 'OTP send failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'LOGIN_HIDE_LOADER' });
            dispatch({ type: 'OTP_SEND_HIDE_LOADER' });

        });
};
export const verifyOtpAction = (inputData) => async (dispatch) => {
    dispatch({ type: 'VERIFICATION_SHOW_LOADER' });
    await axios
        .post(verifyOtpUrl(), inputData, { Headers: { ...getToken().headers } })
        .then(function ({ data }) {
            dispatch({ type: 'VERIFICATION_HIDE_LOADER' });
            if (data.success) {
                dispatch({
                    type: 'SET_OTP_VERIFIED',
                    payload: true,
                });
                return;
            }
            else {
                notification.error({
                    message: 'Verification failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'VERIFICATION_HIDE_LOADER' });
        });
};
export const registerWithCognito = (inputData, recaptchaToken) => async (dispatch) => {
    dispatch({ type: 'REGISTER_SHOW_LOADER' });
    await axios
        .post(registerUrl(), inputData, { Headers: { captcha: recaptchaToken } })
        .then(async function ({ data }) {
            if (!data.error) {
                try {
                    const newUser = await Auth.signUp({
                        username: data.user.email,
                        password: inputData.password,
                        attributes: {
                            email: data.user.email,
                            "custom:userType": data.user.userType,
                            "custom:name": data.user.name,
                            "custom:mobile": data.user.mobile,
                            "custom:companyId": data.user.companyId,
                            "custom:suspentionTime": data.user.suspentionTime,
                            "custom:id": data.user.id,
                            "custom:mcpGroups": [...data.user.mcpGroups].join(',')
                        }
                    });
                    dispatch({ type: 'REGISTER_HIDE_LOADER' });

                    message.info('A verification link is sent on your email.', 10)
                    setTimeout(() => {
                        dispatch(push('/'))
                    }, 300)
                } catch (e) {
                    dispatch({ type: 'REGISTER_HIDE_LOADER' });

                    console.log(e)
                    notification.error({
                        message: 'Sign up failed',
                        description: e.message
                    })
                }
            } else {
                dispatch({ type: 'REGISTER_HIDE_LOADER' });

                notification.error({
                    message: 'Signing up failed',
                    description: data.message
                })
            }
        })
        .catch(function (error) {
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'REGISTER_HIDE_LOADER' });
        });
};