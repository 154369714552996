import React, { Fragment } from 'react'
import styles from './AuthLayout.less'
import logo from '../assets/ht logo.svg'
import { Link } from 'react-router-dom'
import GlobalFooter from '../components/GlobalFooter'
import { subTitle, pageTitle } from '../settings'
import {CopyrightCircleFilled} from '@ant-design/icons'

const links = [
  {
    key: 'help',
    title: 'HELP',
    href: ''
  }
]

const copyright = (
  <Fragment>
    Copyright <CopyrightCircleFilled/> 2018
  </Fragment>
)

class UserLayout extends React.PureComponent {

  render () {
    const { children } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.cover}>
          <div className={styles.left}>
            <img src={'https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/bottom-left-amcu.png'}/>
          </div>
          <div className={styles.right}>
            <img
              src={'https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/mobile-app--amcu--process-with-cloud-right.png'}/>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.logo}>
              <img src={'https://ishaan-amcu.s3-us-west-2.amazonaws.com/static/ht-amcu-log.png'}/>
            </div>

            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default UserLayout
