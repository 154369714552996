export default (
    state = {
        smsPurchaseLoader: false,
        smsPurchaseList: {data:[]}
    },
    action
) => {
    switch (action.type) {
        case "SMS_PURCHASE_SHOW_LOADER":
            return { ...state, smsPurchaseLoader: true };
        case "SMS_PURCHASE_HIDE_LOADER":
            return { ...state, smsPurchaseLoader: false };
        case "SMS_PURCHASE_LIST":
            return { ...state, smsPurchaseList: action.payload };
        default:
            return state;
    }
};
