import { combineReducers } from 'redux'
import counter from './counter'
import theme from './theme'
import global from './global'
import login from '../elements/login/reducers/loginReducers'
import register from '../elements/register/reducers/registerReducers'
import announcement from '../elements/announcement/reducers/announcementsReducers'
import dashboard from '../elements/dashboard/reducers/dashboardReducers'
import conflict from '../elements/conflicts/reducers/conflictReducer'
import smsPurchase from '../elements/smsPurchase/reducers/smsPurchaseReducers'
import newDashboard from '../elements/dashboardNew/reducers/dashboardNewReducers'
import companySettings from '../elements/companySettings/reducer/companySettingReducer'
import bluetooth from '../elements/bluetoothSettings/reducer/bluetoothSettingsReducer'
import logs from '../elements/SyncLogs/reducer/Reducer'



import { createBrowserHistory as createHistory } from 'history'
import { connectRouter } from 'connected-react-router'
export const history = createHistory()


export default combineReducers({
  counter,
  theme,
  global,
  login,
  register,
  announcement,
  bluetooth,
  dashboard,
  newDashboard,
  smsPurchase,
  conflict,
  companySettings,
  logs,
  router: connectRouter(history)

})
