export default (
    state = {
        announcementLoader: false,
        announcementList: {data:[]},
        announcementResp: {},
    },
    action
) => {
    switch (action.type) {
        case "ANNOUNCEMENT_SHOW_LOADER":
            return { ...state, announcementLoader: true };
        case "ANNOUNCEMENT_HIDE_LOADER":
            return { ...state, announcementLoader: false };
        case "ANNOUNCEMET_LIST":
            return { ...state, announcementList: action.payload };
        case "ANNOUNCEMET_RESPONSE":
            return { ...state, announcementResp: action.payload };
        default:
            return state;
    }
};
