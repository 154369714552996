import MenuData from '../../routes'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import styles from './styles.less'
import { MenuOutlined , TeamOutlined, UserAddOutlined} from '@ant-design/icons'
import React from 'react'
{
  /* <MenuOutlined /> */
}

let filteredMenuData = []

const getNavMenuItems = (menusData, filter) => {
  if (!menusData) {
    return []
  }
  let filtered = menusData.filter((item) => item.name && !item.hideInMenu)
  filtered.forEach((item) => {
    let condition = true
    //check for authority
    if (item.authority && filter && filter.userType) {
      if (!item.authority.includes(filter.userType)) {
        condition = false
      }
    }
    if (!item.dontShowOnMenu && condition) {
      getSubMenuOrItem(item)
    }
  })
  return [...filteredMenuData]
}

const getSubMenuOrItem = (item) => {
  if (!item.children) {
    let modifiedItem = {
      ...item,
      type: 'menu',
      menuName: item.menuName ? item.menuName : '',
    }
    filteredMenuData.push(modifiedItem)
  }
  if (
    item.children &&
    !item.hideChildrenInMenu &&
    item.children.some((child) => child.name)
  ) {
    item.children = item.children.map((a) => {
      return {
        ...a,
        icon: item.icon,
        menuName: `${item.menuName ? item.menuName : item.name} > ${
          a.name || ''
        }`,
        type: 'menu',
      }
    })
    getNavMenuItems(item.children)
  }
}

export const getMenuArr = (data) => {
  filteredMenuData = []
  let items = getNavMenuItems(MenuData, data)
  return items
}

export const getMenuHtml = (item) => {
  return (
    <div className={styles.menuItem} >
      <MenuOutlined style={{color:'gray'}} />
      <div className={styles.itemInfoWrap}>
      <div className={styles.name}>
        {item.menuName}
      </div>
      <span style={{color:'gray', fontSize:'14px'}}>Menu</span>
      </div>
    </div>
  )
}

export const getFarmerHtml = (item) => {
  return (
    <div className={styles.menuItem} >
      <TeamOutlined style={{color:'gray', fontSize:'20px'}} />
      <div className={styles.itemInfoWrap}>
      <div className={styles.name}>
        {item.menuName} ({item.farmerId})
      </div>
      <div className={styles.farmerInfo}>
      <div className={styles.infoText}>{item.phone}</div>
      <div className={styles.infoText}>Bal: {item.balance || 0}</div>
      </div>
      </div>
    </div>
  )
}

export const getMcpHtml = (item) => {
  return (
    <div className={styles.menuItem} >
      <UserAddOutlined style={{color:'gray', fontSize:'20px'}} />
      <div className={styles.itemInfoWrap}>
      <div className={styles.name}>
        {item.name} ({item.mcpId})
      </div>
      <div className={styles.farmerInfo}>
      <div className={styles.infoText}>{item.mcpGroup}</div>
      <div className={styles.infoText}>{item.contactName || ''}{item.mobile? (item.mobile):''}</div>
      {/* <div className={styles.infoText}>Bal: {item.balance || 0}</div> */}
      </div>
      </div>
    </div>
  )
}

export const getMenuItem = (item) => {
  switch(item.type){
    case 'menu': return getMenuHtml(item)
                 break;
    case 'farmers': return getFarmerHtml(item)
                 break;
    case 'mcp': return getMcpHtml(item)
                 break;
    default: return item.menuName
  }
}
