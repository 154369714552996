import React, { PureComponent } from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import styles from './index.less';
import { QuestionCircleOutlined } from '@ant-design/icons';

export default class HeaderDropdown extends PureComponent {
  render() {
    const { overlayClassName, ...props } = this.props;
    return (<div className={styles.flex}>
      <QuestionCircleOutlined style={{ color: 'white', fontSize: '18px' }} />

      <Dropdown overlayClassName={classNames(styles.container, overlayClassName)} {...props} />
    </div>
    );
  }
}
