import { notification } from 'antd'
import axios from 'axios'
import FileSaver from 'file-saver'

import { apiUrl } from './settings'
import { orderBy } from 'lodash'

export const API_URL = apiUrl
let authAxios = axios.create({
  baseURL: apiUrl,
})

export const getToken = () => {
  const REACT_APP_AUTH_TYPE = process.env.REACT_APP_AUTH_TYPE
  let tokenType = 'BACK_OFFICE'
  if (REACT_APP_AUTH_TYPE == 'COGNITO') {
    tokenType = 'COGNITO'
  }
  return {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      type: tokenType,
    },
  }
}

function download(filename) {
  fetch(apiUrl + '/download/' + filename, getToken()).then(function (t) {
    let name = t.headers.get('Content-Disposition')
    if (name) {
      name = name.split('=')[1].replace(/['"]+/g, '')
      return t.blob().then((b) => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', name)
        a.click()
      })
    } else {
      notification.error({ message: 'Error downloading' })
    }
  })
}

class Request {
  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.clear()
        window.location.reload()
      }
    } catch (e) {}
  }

  constructor() {}

  //cognito login
  /*   login(data, recaptchaToken,tokens) {
        return new Promise((next, error) => {
          authAxios
            .post('/loginWithToken', data,{ headers: { captcha:recaptchaToken,Authorization: 'Bearer ' + tokens, type: 'BACK_OFFICE' }})
            .then(d => {
              next(d.data)
            })
            .catch(err => {
              next({ error: true, err })
              this.error(err)
            })
        })
      }
     */
  login(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post('/loginWithPassword', data, {
          headers: { captcha: recaptchaToken },
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getDashboardLastData(data) {
    return new Promise((next) => {
      authAxios
        .get('/lastInsertedCollection', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addFarmer(data) {
    return new Promise((next) => {
      authAxios
        .post('/farmer', { ...data, verified: true }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllFarmers(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmer', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerById(id) {
    return new Promise((next) => {
      authAxios
        .get('/farmer/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateFarmer(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/farmer/' + id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteFarmer(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/farmer/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  restoreFarmer(id) {
    return new Promise((next) => {
      authAxios
        .patch(`/farmer/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addMcp(data) {
    return new Promise((next) => {
      authAxios
        .post('/mcp', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listCategory(data = {}) {
    return new Promise((next, error) => {
      authAxios
        .get(`/farmerCategory`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listRateChartFarmerCategory(data = {}) {
    return new Promise((next, error) => {
      authAxios
        .get(`/rateChartFarmerCategory`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllMcp(data) {
    return new Promise((next) => {
      authAxios
        .get('/mcp', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data && d.data.data) {
            if (!data.ignoreLocalOrder) {
              d.data.data = orderBy(
                d.data.data,
                [(user) => user.name.toLowerCase()],
                ['asc']
              )
            }
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllMachines(data) {
    return new Promise((next) => {
      authAxios
        .get('/user', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getUser(data) {
    return new Promise((next) => {
      authAxios
        .get('/user', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getRmrdDevices(data) {
    return new Promise((next) => {
      authAxios
        .get('/rmrdDevice', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  DeleteMcpUser(data) {
    return new Promise((next) => {
      authAxios
        .delete(`/user/` + data._id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMcpById(id) {
    return new Promise((next) => {
      authAxios
        .get('/mcp/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCompleteMcpById(id) {
    return new Promise((next) => {
      authAxios
        .get('/getCompleteMcp/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateMcp(data) {
    return new Promise((next) => {
      authAxios
        .put('/mcp/' + data._id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteMcp(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/mcp/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  restoreMcp(id) {
    return new Promise((next) => {
      authAxios
        .patch(`/mcp/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCollectionCenter(data) {
    return new Promise((next) => {
      authAxios
        .get('/collectionCenter', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCollectionById(data) {
    return new Promise((next) => {
      authAxios
        .get('/collectionCenterById', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  saveCollectionSummary(data) {
    return new Promise((next) => {
      authAxios
        .post('/saveCollectionSummary', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMachineDataByCollectionId(data) {
    return new Promise((next) => {
      authAxios
        .get('/machineSummaryByCollectionId', {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addCollection(data) {
    return new Promise((next) => {
      authAxios
        .post('/collection', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCollections(data = {}) {
    // console.log('reqdata', data)
    return new Promise((next) => {
      authAxios
        .get('/collection', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateCollections(data = {}) {
    // console.log('reqdata', data)
    return new Promise((next) => {
      authAxios
        .get('/collection', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteCollection(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/collection/` + id, getToken())
        .then((d) => {
          console.log('after del data is', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLiveCollections(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/collection/quantity', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLiveCollectionsType(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/collection/type', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCollectionsExcel(data = {}) {
    return new Promise((next) => {
      authAxios({
        url: '/collection/export',
        method: 'GET',
        params: { ...data },
        ...getToken(),
      }).then((response) => {
        // FileSaver.saveAs(new Blob([response.data]), 'ishaan.xlsx')
        if (!response.data.error) {
          download(response.data.fileName)
        }
        next({ ...response.data })
      })

      /*
             authAxios
             .get('/collection/exel', { params: { ...data } }, getToken())
             .then((d) => {
             next(d.data)
             })
             .catch((err) => {
             next({ error: true, err })
             this.error(err)
             })
             */
    })
  }

  getMcpMilkBillReportExcel(data = {}) {
    return new Promise((next) => {
      authAxios({
        url: '/mcpMilkBillReport/export',
        method: 'GET',
        params: { ...data },
        ...getToken(),
      }).then((response) => {
        if (!response.data.error) {
          download(response.data.fileName)
        }
        next({ ...response.data })
      })
    })
  }

  getFarmerMilkBillReportExcel(data = {}) {
    return new Promise((next) => {
      authAxios({
        url: '/farmerMilkBillReport/export',
        method: 'GET',
        params: { ...data },
        ...getToken(),
      }).then((response) => {
        if (!response.data.error) {
          download(response.data.fileName)
        }
        next({ ...response.data })
      })
    })
  }

  getFarmerLedgerReportExcel(data = {}) {
    return new Promise((next) => {
      authAxios({
        url: '/farmerLedgerReport/export',
        method: 'GET',
        params: { ...data },
        ...getToken(),
      }).then((response) => {
        if (!response.data.error) {
          download(response.data.fileName)
        }
        next({ ...response.data })
      })
    })
  }

  generateManualFarmerBills(data = {}) {
    return new Promise((next) => {
      authAxios
        .post('/admin/bill/generate/manual', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  generateFarmerUnbilledCollections(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/farmer/billGenerate', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerBills(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/farmerBill', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getEditCollectionsExcel(data = {}) {
    return new Promise((next) => {
      authAxios({
        url: '/editCollection/export',
        method: 'GET',
        params: { ...data },
        ...getToken(),
      }).then((response) => {
        if (!response.data.error) {
          download(response.data.fileName)
        }
        next({ ...response.data })
      })
    })
  }

  addUser(data) {
    return new Promise((next) => {
      authAxios
        .post('/user', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addRmrdDevice(data) {
    return new Promise((next) => {
      authAxios
        .post('/rmrdDevice', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateUser(id, data) {
    return new Promise((next) => {
      authAxios
        .put(`/user/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUser(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/user/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  resetPassword(email, data) {
    return new Promise((next) => {
      authAxios
        .post('/changePassword', { email, data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteRate(id) {
    return new Promise((next, error) => {
      authAxios
        .delete(`/rate/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addRateChartDetails(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/rateChartDetails', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getRateChartCSVData(data) {
    return new Promise((next) => {
      authAxios
        .post('/csv/rateChart', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getRateChartDetails(data) {
    return new Promise((next, error) => {
      authAxios
        .get('/rateChartDetails', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateRateChartDetails(id, data = {}) {
    return new Promise((next, error) => {
      authAxios
        .put(`/rateChartDetails/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateRate(id, data = {}) {
    return new Promise((next, error) => {
      authAxios
        .put(`/rate/${id}`, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listRate(data = {}) {
    return new Promise((next, error) => {
      authAxios
        .get(`/rate`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listRateChart(data = {}) {
    return new Promise((next, error) => {
      authAxios
        .get(`/rate`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listRateChartApi(data = {}) {
    return new Promise((next, error) => {
      authAxios
        .get(`/rate/api/list`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addRate(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/rate', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addCategory(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/farmerCategory', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  upload(data = {}) {
    return new Promise((next, error) => {
      authAxios
        .post(`/upload/file`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportJson(data) {
    return new Promise((next) => {
      authAxios
        .post('/upload/json', data, getToken())
        .then((d) => {
          next(d.data)
          // if (!d.data.error) {
          //   window.open(apiUrl + '/download/' + d.data.filename)
          //   window.close()
          // }
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  importJsontoXls(file) {
    return new Promise((next) => {
      authAxios
        .post('/upload/csv', { csv: file }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addMilkAnalyserOptn(data) {
    return new Promise((next) => {
      authAxios
        .post('/milkAnalyserOptions', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  // updateMilkAnalyserOptn (data) {
  //   return new Promise(next => {
  //     authAxios
  //       .put('/milkAnalyserOptions/' + data.mcp, data, getToken())
  //       .then(d => {
  //         next(d.data)
  //       })
  //       .catch(err => {
  //         next({ error: true, err })
  //         this.error(err)
  //       })
  //   })
  // }

  updateMilkAnalyserOptn(data) {
    return new Promise((next) => {
      authAxios
        .post('/editMilkScannerOptions', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addFarmerSettings(data) {
    return new Promise((next) => {
      authAxios
        .post('/farmerSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateFarmerSettings(id, data) {
    return new Promise((next) => {
      authAxios
        .put(`/farmerSettings/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listFarmerSettings(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmerSettings', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSetting(id) {
    return new Promise((next) => {
      authAxios
        .get('/setting/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateSetting(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/setting/' + id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addMcpGroup(data) {
    return new Promise((next) => {
      authAxios
        .post('/mcpGroup', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateMcpGroup(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/mcpGroup/' + id, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllGroup(data) {
    return new Promise((next) => {
      authAxios
        .get('/mcpGroup', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data && d.data.data) {
            d.data.data = orderBy(
              d.data.data,
              [(user) => user.groupName.toLowerCase()],
              ['asc']
            )
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteMcpGroup(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/mcpGroup/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMcpGroupById(id) {
    return new Promise((next) => {
      authAxios
        .get(`/mcpGroup/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  restoreMcpGroup(id) {
    return new Promise((next) => {
      authAxios
        .patch(`/mcpGroup/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addFarmerExtraFields(data) {
    return new Promise((next) => {
      authAxios
        .post('/farmerExtraFields', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateAnalyserSettings(data) {
    return new Promise((next) => {
      authAxios
        .post('/updateAnalyserSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAnalyserSettings(data) {
    return new Promise((next) => {
      authAxios
        .post('/getAnalyserSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateFarmerExtraFields(data, id) {
    return new Promise((next) => {
      authAxios
        .put('/farmerExtraFields/' + id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listFarmerExtraFields(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmerExtraFields', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerExtraFields(id) {
    return new Promise((next) => {
      authAxios
        .get('/farmerExtraFields/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMilkAnalyserOptn(data) {
    return new Promise((next) => {
      authAxios
        .get('/milkScannerOptions', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addFarmerCategory(data) {
    return new Promise((next) => {
      authAxios
        .post('/farmerCategory', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateFarmerCategory(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/farmerCategory/' + id, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllFarmerCategory(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmerCategory', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteFarmerCategory(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/farmerCategory/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerCategoryById(id) {
    return new Promise((next) => {
      authAxios
        .get(`/farmerCategory/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  restoreFarmerCategory(id) {
    return new Promise((next) => {
      authAxios
        .patch(`/farmerCategory/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getPayments(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/payment', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getMachineLogs(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/machineLogs', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  getCleaningLogs(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/cleaningLogs', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportCollection(data = {}) {
    return new Promise((next) => {
      authAxios
        .post('/collection/export', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateCollectionsData(id, data) {
    return new Promise((next) => {
      console.log(data, 'update collection checkj11')
      authAxios
        .put(`/collection/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  exportFarmer(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/farmer/export', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (!d.data.error) {
            console.log(d.data.fileName)
            download(d.data.fileName)
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getRateChart(data) {
    return new Promise((next) => {
      authAxios
        .get(`rate/${data}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateRateChartDetailsArray(data, id) {
    return new Promise((next, error) => {
      authAxios
        .post(
          `/updateRateChartDetails`,
          { rateCharts: data, rateChartId: id },
          getToken()
        )
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateFatRateInRateChart(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/updateFatRateInRateChart`, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateStandardRateChart(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/updateStandardRateChart`, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getOldCollections(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/oldCollection', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSummaryReport(data) {
    return new Promise((next) => {
      authAxios
        .get('/collection/summary', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (!d.data.error) {
            download(d.data.fileName)
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerUnbilledCollections(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmer/unbilledCollections', {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  checkNonActiveMcp(id) {
    return new Promise((next) => {
      authAxios
        .get('/mcp/offline/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/incentiveSettings`, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllIncentivesSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .get('/incentiveSettings', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSingleIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/getSingleIncentiveSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/updateIncentiveSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmersLedger(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/farmerLedger', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBestWorstFarmer(data) {
    return new Promise((next, error) => {
      authAxios
        .get('/bestFarmer/dashboard', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBestWorstMcp(data) {
    return new Promise((next, error) => {
      authAxios
        .get('/bestMcp/dashboard', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerBillDetail(data) {
    return new Promise((next, error) => {
      authAxios
        .get('/farmerBillDetail', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMilkDispatch(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/milkDispatch', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMcpSettingPassword(data) {
    return new Promise((next) => {
      authAxios
        .post('/setting/getMcpPassword', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addBulkCollection(data) {
    return new Promise((next) => {
      authAxios
        .post('/bulkCollection', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateBulkCollection(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/bulkCollection/' + id, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateSettingPassword(data) {
    return new Promise((next) => {
      authAxios
        .post('/setting/updateSettingPassword', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  allBulkCollection(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/bulkCollection', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBulkCollectionById(id) {
    return new Promise((next) => {
      authAxios
        .get('/bulkCollection/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBulkCollection(data) {
    return new Promise((next) => {
      authAxios
        .get('/getBulkCollection', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  resetMcpLoginApi(data) {
    return new Promise((next) => {
      authAxios
        .post('/resetMcpLogin', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  transferMcpApi(data) {
    return new Promise((next) => {
      authAxios
        .post('/transferMcp', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addPayment(data) {
    return new Promise((next) => {
      authAxios
        .post('/payment', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addCollectionQuantityIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/collectionQuantityIncentiveSettings`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  editCollectionQuantityIncentiveSettings(id, data) {
    return new Promise((next, error) => {
      authAxios
        .put(`/collectionQuantityIncentiveSettings/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listCollectionQuantityIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .get(`/collectionQuantityIncentiveSettings`, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addBillSetting(data = {}) {
    return new Promise((next) => {
      authAxios
        .post('/automaticBillingSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateBillSetting(id, data = {}) {
    return new Promise((next) => {
      authAxios
        .put(`/automaticBillingSettings/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBillSetting(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get(`/automaticBillingSettings`, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllTemplates(data) {
    return new Promise((next) => {
      authAxios
        .get('/smsTemplate', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getTemplate(id) {
    return new Promise((next) => {
      authAxios
        .get(`/smsTemplate/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addTemplate(data) {
    return new Promise((next) => {
      authAxios
        .post('/smsTemplate', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateTemplate(id, data) {
    return new Promise((next) => {
      authAxios
        .put(`/smsTemplate/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteTemplate(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/smsTemplate/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllSms(data) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/sms', { params: { ...data }, ...getToken() })
        .then((d) => {
          console.log('qqqqqqq', d.data)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllAnnouncement(data) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/announcement', { params: { ...data }, ...getToken() })
        .then((d) => {
          console.log('qqqqqqq', d.data)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCompanyById(id) {
    return new Promise((next) => {
      authAxios
        .get(`/company/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllSetting(data) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/setting', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  farmerBillsExport(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/farmerBill/export', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (!d.data.error) {
            download(d.data.fileName)
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listBonusQuantityIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .get(`/bonusQuantityIncentiveSettings`, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addBonusQuantityIncentiveSettings(data) {
    return new Promise((next, error) => {
      authAxios
        .post(`/bonusQuantityIncentiveSettings`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  editBonusQuantityIncentiveSettings(id, data) {
    return new Promise((next, error) => {
      authAxios
        .put(`/bonusQuantityIncentiveSettings/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCompanySettings(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/company/settings', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getPurchaseSummary(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/purchaseSummaryList', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getTransactions(data = {}) {
    return new Promise((next) => {
      authAxios
        .post('/farmerReports', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

 

  getMcpReport(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/dailyMcpReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addBulkPurchase(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .post('/bulkMilkPurchase', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getBulkPurchases(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/bulkMilkPurchase', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateBulkPurchase(id, data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .put(`/bulkMilkPurchase/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  sendOtp(mobileNumber) {
    return new Promise((next) => {
      authAxios
        .get(`/sendOtp`, { params: { mobileNumber }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  verifyOtp(data) {
    return new Promise((next) => {
      authAxios
        .post(`/verifyOtp`, { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  verifyOtpAndReset(data) {
    return new Promise((next) => {
      authAxios
        .get(`/resetPassword`, { params: { ...data }, ...getToken() })
        // .post(`/resetPassword`, { ...data }, getToken())
        .then((d) => {
          console.log('inside then of otp')
          next(d.data)
        })
        .catch((err) => {
          console.log('inside catchh')
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  register(data, recaptchaToken) {
    return new Promise((next, error) => {
      authAxios
        .post(
          `/register`,
          { ...data },
          { headers: { captcha: recaptchaToken } }
        )
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getFarmerTitleSetting(data) {
    return new Promise((next) => {
      authAxios
        .get(`/getFarmerTitleSetting`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAutoMilkType(data) {
    return new Promise((next) => {
      authAxios
        .get('/getAutoMilkType', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllCollectionFields(id) {
    return new Promise((next) => {
      authAxios
        .get('/allCollectionFields/', getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getCollectionExportSettings(data) {
    return new Promise((next) => {
      authAxios
        .get('/getCollectionExportSetting', {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMachineSettings(data) {
    return new Promise((next) => {
      authAxios
        .get(`/getMachineSettings`, { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateOnlyMcp(id, data) {
    return new Promise((next) => {
      authAxios
        .put('/updateMcp/' + id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addProduct(data) {
    return new Promise((next) => {
      authAxios
        .post('/product', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listProducts(data) {
    return new Promise((next) => {
      authAxios
        .get('/product', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getProductById(id, data) {
    return new Promise((next) => {
      authAxios
        .get(`/product/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateProduct(id, data) {
    return new Promise((next) => {
      authAxios
        .put(`/product/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listSales(data) {
    return new Promise((next) => {
      authAxios
        .get('/sale', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAverages(data) {
    return new Promise((next) => {
      authAxios
        .get('/collectionAvg', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getEditMilkReport(data) {
    return new Promise((next) => {
      authAxios
        .get('/editMilkReport', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  sendAuthOtp(data) {
    return new Promise((next) => {
      authAxios
        .get('/authSendOtp', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  verifyAuthOtp(data) {
    return new Promise((next) => {
      authAxios
        .get('/authVerifyOtp', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addAnnouncement(data) {
    return new Promise((next) => {
      authAxios
        .post('/announcement', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllConflicts(data) {
    return new Promise((next) => {
      authAxios
        .get('/fetch/cloud/conflicts', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  purchaseSmsCredit(data) {
    return new Promise((next) => {
      authAxios
        .post('/sms/purchase', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  makePayment(data) {
    return new Promise((next) => {
      authAxios
        .post('/secret/paymentIntent', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  purchaseSmsList(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/sms/purchase', { params: data, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  McpDetailCount(data) {
    console.log('requestdata', data)
    return new Promise((next) => {
      authAxios
        .get('/mcp/details', { params: { ...data }, ...getToken() })
        .then((d) => {
          console.log('AAAAQQQQQQ', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteMcpAndDetails(data) {
    console.log('requestdata', data)
    return new Promise((next) => {
      authAxios
        .delete('/mcp/softdelete', { params: { ...data }, ...getToken() })
        .then((d) => {
          console.log('AAAAQQQQQQ', d)
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  FarmerDetailCount(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmer/details', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteFarmerAndDetails(data) {
    return new Promise((next) => {
      authAxios
        .delete('/farmer/softdelete', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSmsPacks(data) {
    return new Promise((next) => {
      authAxios
        .get('/smsPacks', getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ///new

  //  getReport(data){
  //   return new Promise((next) => {
  //     authAxios
  //       .get('/reporttts', { ...getToken() })
  //       .then((response) => {
  //         next(response.data);
  //       })
  //       .catch((err) => {
  //         next({ error: true, err });
  //         this.error(err);
  //       });
  //   });
  // };

  getReports(data) {
    return new Promise((next) => {
      authAxios
        .get('/get/reportsFormat', { ...getToken() })
        .then((response) => {
          next(response.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ///

  getCompanyStatus(data) {
    return new Promise((next) => {
      authAxios
        .get('/company/status', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSyncLogs(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/syncLogs', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  enterpriseDetails(id) {
    return new Promise((next) => {
      authAxios
        .get('/esper/device/' + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  enterpriseCommands(data) {
    return new Promise((next) => {
      authAxios
        .post('/esper/commands/' + data.id, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getEsperApps(id) {
    return new Promise((next) => {
      authAxios
        .get(`/esper/device/${id}/app`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addRmrdAnalyzerParameter(data) {
    return new Promise((next) => {
      authAxios
        .post('/rmrdAnalyzerParameter/add', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listRmrdAnalyzerParameters(data) {
    return new Promise((next) => {
      authAxios
        .post('/rmrdAnalyzerParameter/list', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getParameterById(id) {
    return new Promise((next) => {
      authAxios
        .get(`/rmrdAnalyzerParameter/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteParameter(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/rmrdAnalyzerParameter/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  editRmrdAnalyzerParameter(id, data) {
    return new Promise((next) => {
      authAxios
        .put(`/rmrdAnalyzerParameter/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  listRmrdCollections(data) {
    return new Promise((next) => {
      authAxios
        .post('/rmrdCollections/list', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getUserRightsById(data) {
    return new Promise((next) => {
      authAxios
        .get(`/user/getRights/${data.id}`, {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addReportPrintSetting(data) {
    return new Promise((next) => {
      authAxios
        .post('/reportPrintSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  downloadPdfReport(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadPdfReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
 
  downloadMcpPdfReport(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadMcpPdfReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
  downloadFarmerBankReport(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/farmerBankPdfReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
    exportFarmerBankReport(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadfarmerBankReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  downloadCsvReport(data = {}) {
    console.log(data)
    return new Promise((next) => {
      authAxios
        .get('/downloadCsvReport', { params: { ...data }, ...getToken() })
        .then((d) => {
          console.log('d is', d)
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next('done')
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportCollectionsCsv(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadCollectionsCsv', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportBillsCsv(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadBillsCsv', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportAllFarmersCsv(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadAllFarmersCsv', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportBillsPdf(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadBillsPdf', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportAllBillsPdf(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadAllBillsPdf', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportAllFarmersPdf(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadAllFarmersPdf', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllReportPrintSettings(data) {
    return new Promise((next) => {
      authAxios
        .get('/reportPrintSettings', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getReportSettingById(id) {
    return new Promise((next) => {
      authAxios
        .get(`/reportPrintSetting/${id}`, {
          ...getToken(),
        })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllFarmersBalance(data) {
    console.log('data  ', data)
    return new Promise((next) => {
      authAxios
        .get('/getAllFarmerBalance', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  payAllFarmerBalance(data) {
    return new Promise((next) => {
      authAxios
        .post('/payAllFarmerBalance', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateReportPrintSetting(data) {
    return new Promise((next) => {
      authAxios
        .put('/reportPrintSettings', data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteFarmer(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/farmer/` + id, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteReportPrintSetting(id) {
    return new Promise((next) => {
      authAxios
        .delete(`/reportPrintSetting/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportPaymentsCsv(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadPaymentsCsv', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportSalesCsv(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadSalesCsv', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportSalesPdf(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadSalesPdf', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportFarmerBalancePdf(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadFarmerBalancePdf', {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportFarmerBalanceCsv(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadFarmerBalanceCsv', {
          params: { ...data },
          ...getToken(),
        })
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  // exportFarmerSummaryCsv(data = {}) {
  //   return new Promise((next) => {
  //     authAxios
  //       .post('/download/mcp/farmers/summary/csv', {
  //         params: { ...data },
  //         ...getToken(),
  //       })
  //       .then((d) => {
  //         if (d.data) {
  //           let linkValue = API_URL + `/${d.data.fileName}`
  //           window.open(linkValue, 'download')
  //         }
  //         next(d.data)
  //       })
  //       .catch((err) => {
  //         next({ error: true, err })
  //         this.error(err)
  //       })
  //   })
  // }




  exportFarmerSummaryCsv(data={}) {
    console.log("first check",data)
    return new Promise((next) => {
      console.log("2nd check")
      authAxios
        .post('/download/mcp/farmers/summary/csv', 
       { ...data }, getToken())
        .then((d) => {
          console.log(d,"here.....")
          if (d.data) {
            console.log("3rd check")
            console.log('4rd check',d)
            let linkValue = API_URL + `/${d.data.csvOutput}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  exportFarmerSingleSummaryCsv(data={}) {
    console.log("first check",data)
    return new Promise((next) => {
      console.log("2nd check")
      authAxios
        .post('/download/mcp/farmers/single/summary/csv', 
       { ...data }, getToken())
        .then((d) => {
          console.log(d,"here.....")
          if (d.data) {
            console.log("3rd check")
            console.log('4rd check',d)
            let linkValue = API_URL + `/${d.data.csvOutput}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  getPaymentGraphData(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/getGraphData/payment', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updatePayment(id, data) {
    return new Promise((next) => {
      authAxios
        .put(`/payment/${id}`, data, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getPaymentById(id, data) {
    return new Promise((next) => {
      authAxios
        .get(`/payment/${id}`, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  exportBillDetailsPdf(data = {}) {
    return new Promise((next) => {
      authAxios
        .get('/downloadBillDetailsPdf', { params: { ...data }, ...getToken() })
        .then((d) => {
          if (d.data) {
            if (d.data.pdfName) {
              let linkValue = API_URL + `/${d.data.pdfName}`
              window.open(linkValue, 'download')
            }
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getUniqueBills(data) {
    return new Promise((next) => {
      authAxios
        .get('/farmerBill/distinct', { params: { ...data }, ...getToken() })
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  generateFarmerSummaryPdf(data) {
    return new Promise((next) => {
      authAxios
        .post('/generate/farmerSummary', { ...data }, getToken())
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.fileName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }


  
  
  // generateBankReportPdf(data) {
  //   return new Promise((next) => {
  //     authAxios
  //       .post('/generate/farmerSummary', { ...data }, getToken())
  //       .then((d) => {
  //         if (d.data) {
  //           let linkValue = API_URL + `/${d.data.fileName}`
  //           window.open(linkValue, 'download')
  //         }
  //         next(d.data)
  //       })
  //       .catch((err) => {
  //         next({ error: true, err })
  //         this.error(err)
  //       })
  //   })
  // }

  getFarmerSummary(data) {
    return new Promise((next) => {
      authAxios
        .post('/farmer/report/summary', { ...data }, getToken())
        .then((d) => {
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getMcpSummaryPdf(data) {
    return new Promise((next) => {
      authAxios
        .post('/mcpSummary', { ...data }, getToken())
        .then((d) => {
          if (d.data) {
            let linkValue = API_URL + `/${d.data.pdfName}`
            window.open(linkValue, 'download')
          }
          next(d.data)
        })
        .catch((err) => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
}

export default new Request()
