export default (state = {
    bluetoothSettingsList: {}
}, action) => {
    switch (action.type) {
        case 'BLUETOOTH_SETTINGS_SHOW_LOADER':
            return { ...state, bluetoothLoader: true };

        case 'BLUETOOTH_SETTINGS_HIDE_LOADER':
            return { ...state, bluetoothLoader: false };

        case 'BLUETOOTH_SETTINGS':
            return { ...state, bluetoothSettingsList: action.payload };

        default:
            return state;

    }
};
