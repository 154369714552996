import React, { PureComponent, useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import async from 'async'

import { SummaryAction } from '../action/dashboardAction'
import { connect, useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import styles from './styles.less'
import { priceformatter } from '../../../components/_utils/utils'

function Summary(props) {
    const { currentUser } = props

    const dispatch = useDispatch()

    const { refreshBox } = useSelector((state) => ({
        refreshBox: state.dashboard.refreshBox
    })
    )

    useEffect(() => {
        apiRes()
    }, [])

    let apiRes = () => {
        let filter = {
            companyId: currentUser.companyId
        }
        if (currentUser.userType === 'manager')
            filter.mcpGroupId = currentUser.mcpGroups
        dispatch(SummaryAction(filter))
    }


    const throttled = useRef(_.throttle(() => apiRes(), 10000))

    useEffect(() => throttled.current(), [refreshBox])



    const { summary } = useSelector((state) => ({
        summary: state.dashboard.summary
    })
    )


    const { data: a } = summary;


    return (

        <div>

            {a && <div className={styles.tableWrapper}>
                <div className={styles.heading}>Summary</div>

                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Cow</th>
                            <th>Buffalo</th>
                            <th>All</th>
                        </tr>
                        <tr>
                            <td>Farmers</td>
                            <td>{a.farmer.COW}</td>
                            <td>{a.farmer.BUFFALO}</td>
                            <td>{a.farmer.COW + a.farmer.BUFFALO}</td>
                        </tr>

                        <tr>
                            <td>Qty</td>
                            <td>{a.qty.COW}</td>
                            <td>{a.qty.BUFFALO}</td>
                            <td>{a.qty.COW + a.qty.BUFFALO}</td>
                        </tr>

                        <tr>
                            <td>Avg. FAT</td>
                            <td>{a.fat.COW}</td>
                            <td>{a.fat.BUFFALO}</td>
                            <td>{a.fat.total}</td>
                        </tr>

                        <tr>
                            <td>Avg. SNF</td>
                            <td>{a.snf.COW}</td>
                            <td>{a.snf.BUFFALO}</td>
                            <td>{a.snf.total}</td>
                        </tr>

                        <tr>
                            <td>Avg. Rate</td>
                            <td>{a.rate.COW}</td>
                            <td>{a.rate.BUFFALO}</td>
                            <td>{a.rate.total}</td>

                        </tr>
                    </tbody>
                </table>
            </div>}

        </div>
    )
}
export default Summary